import { Component, Input } from '@angular/core';

// let defaultOptions = {
//   // displayFormat: 'default',
//   // isDebug: false,
// };

@Component({
  selector: 'app-elements-spinner',
  templateUrl: 'elements-spinner.component.html',
  styleUrls: ['../elements.scss', 'elements-spinner.component.scss'],
})
export class ElementsSpinnerComponent {
  @Input() options: any = {};
}
