import { Address } from './../types/address';
import { GiftSubscription } from './../types/gift_subscription';
import { Subscription } from './../types/subscription';

export class TokensInfo {
  public PROD: number;
  public TEST: number;

  constructor() {
    this.PROD = 0;
    this.TEST = 0;
  }
}

export class Account {
  verify_timestamp: number;
  phone_number1: string;
  admins: number[];
  closed: boolean;
  groups: number[];
  seller: boolean;
  public_key: string;
  email1: string;
  email_marketing: number;
  tokens_email_purchases: number;
  tokens_auto_refill_value: number;
  email1_verified: number;
  secret1: string;
  is_group: boolean;
  tokens_notify_below: number;
  updated: number;
  tokens_auto_refill_charge: number;
  created: number;
  created_ip: string; // IP address account created with
  created_country: string; // location of account creation
  created_region: string;
  created_city: string;
  created_lat_long: string;
  created_ipinfo: any; // IPInfo object
  email1_domain: string;
  users: number[];
  buyer: boolean;
  previous_emails: string[];
  totp_enabled: boolean;
  id: number;
  profile_image: string;
  banner_image: string;
  website: string;
  funds: {PROD: number; TEST: number};
  subscriptions: Subscription[];
  gifts: GiftSubscription[];

  email1_new?: string;
  address?: Address; // user or company address

  // Users only
  password_timestamp: number;
  apikey: string;
  first_name: string;
  last_name: string;
  logout_timestamp: number;

  // Groups only
  org?: string;

  constructor() {
    this.address = new Address();
    this.id = 0;
    this.created = 0;
    this.secret1 = '';
    this.buyer = false;
    this.seller = false;
  }
}


