import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface Code {
  percent: number;
  publisher_id: number;
  active?: boolean;
  limit: number;
  count?: number;
  sub_plan_id?: string; // plan ID or ANY so it can be used on any subscription plan from the publisher
}

@Injectable()
export class DiscountCodesService {
  constructor(private http: HttpClient) {

  }

  getPublicProfile(accountId: number): Promise<any> {
    return this.http.get<any>(`/api/account/${accountId}/public/profile`)
      .toPromise();
  }

  getRedemptionPublishers(accountId: number): Promise<any> {
    return this.http.get<any>('/api/util/redemption/code/publishers')
      .toPromise();
  }

  getRedemptionCodeList(accountId: number): Promise<any> {
    return this.http.get<any>(`/api/account/${accountId}/redemption_code/list`)
      .toPromise();
  }

  createSingleCode(accountId: number, code: string, codeDetails: Code): Promise<any> {
    return this.http.put<any>(`/api/account/${accountId}/redemption_code/set/${code}`, codeDetails)
      .toPromise();
  }

  createBatchOfCodes(accountId: number, codeDetails: Code): Promise<any> {
    return this.http.put<any>(`/api/account/${accountId}/redemption_code/generate`, codeDetails)
      .toPromise();
  }

  verifyCode(accountId: number, code: string | number): Promise<any> {
    return this.http.get(`/api/account/${accountId}/redemption_code/verify/${code}`)
      .toPromise();
  }

  deleteDiscountCode(accountId: number, code: string | number): Promise<any> {
    return this.http.delete(`/api/account/${accountId}/redemption_code/remove/${code}`)
      .toPromise();
  }
}
