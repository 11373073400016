import { ErrorMap } from './../../services/form_validation.service';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User, UserService, UtilityService } from '../../index';
import { CurUserService } from '../../services/cur-user.service';

@Component({
  selector: 'app-form-elements-2fa-settings',
  templateUrl: 'form-elements-2fa_settings.component.html',
  styleUrls: ['form-elements-2fa_settings.component.scss'],
  providers: [FormBuilder, UserService, UtilityService]
})

export class TwoFactorAuthSettingsComponent implements OnInit {
  @ViewChild('twoFaElement') svgElement: HTMLElement;
  @ViewChild('twoFaLink') twoFaLink: HTMLElement;

  @Input() userObjectToEdit: Promise<any>;

  enableStep = 0;
  oneUseCodes: string;
  showRescueCodes: boolean;
  twoFaForm: FormGroup;
  user: User;
  error: any = {
    enable: false,
    backBtn: true
  };
  isMobile = false;

  errorMessages: ErrorMap = {};

  constructor(
    private formBuilder: FormBuilder,
    private usrSvc: UserService,
    private utilitySvc: UtilityService,
    private curUserService: CurUserService
  ) {
    this.utilitySvc = new UtilityService();
    /*this.forgotPasswordForm = this.formBuilder.group({
      'email': ['', Validators.required],
    });*/

    this.twoFaForm = this.formBuilder.group({
      authentication_code : '',
    });
  }

  ngOnInit() {
    const user = this.userObjectToEdit as Promise<any>;
    const self = this;
    user.then(usr => {
      self.user = usr;
    });

    this.isMobile = this.detectMobile();
  }

  start2Fa() {
    this.enableStep = 1;
    this.get2FaSVG();
  }

  disable2Fa() {
    this.enableStep = 0;
    this.toggleEnableDisable(false);
  }

  get2FaSVG() {
    this.errorMessages = {};
    this.usrSvc.get2FaCode()
      .then((res: any) => {
        if (this.svgElement) {
          (this.svgElement as any).nativeElement.innerHTML = res.svg;
        } else {
          (this.twoFaLink as any).nativeElement.href = res.url;
        }
      }, () => {
          this.errorMessages = {};
          this.errorMessages.QR = true;
          this.enableError('There was an error with QR code generator, please try again.');
          // this.utilitySvc.showToastDelay('There was an error with QR code generator, try again', 2500);
      });
  }

  enableError(message: string, button?: boolean) {
    Object.assign(this.error, {
      enable: true,
      message,
      backBtn: (button) ? button : true
    });
  }

  disableErrorMsg() {
    this.error.enable = false;
    this.start2Fa();
  }

  showVerification() {
    this.enableStep = 2;
  }

  toggleEnableDisable(enable: boolean) {
    this.errorMessages = {};
    const vCode = this.twoFaForm.controls.authentication_code.value;
    if (enable) {
      this.usrSvc.enable2Fa(vCode)
      .then( res => {
        const resObj = res;
        this.oneUseCodes = _.join(resObj.recovery_codes, ', ');
        this.showRescueCodes = true;
        this.twoFaForm.controls.authentication_code.setValue('');
        // this.utilitySvc.showToastDelay('Two-factor Authentication is now enabled', 2500);
        this.enableStep = 3;
      }).catch((err: HttpErrorResponse) => {
        this.errorMessages = {};
        this.errorMessages[err.error.code] = true;
        this.enableError('Authentication code was not accepted, please try again.');
      });
    } else {  // === 'disable'
      this.usrSvc.disable2Fa()
      .then( res => {
        const updatedUser = res;
        this.user.totp_enabled = updatedUser.totp_enabled;
        this.utilitySvc.showToastDelay('Two-factor Authentication is now turned off.', 2500);
      }).catch((err: HttpErrorResponse) => {
        // debug('disable 2fa error', err);
        this.errorMessages = {};
        this.errorMessages[err.error.code] = true;
        this.enableError(err.error.message);
      });
    }
  }

  copyCodesToClipboard() {
    const codesElement: HTMLTextAreaElement = (document.querySelector('#recovery-codes') as HTMLTextAreaElement);
    codesElement.select();
    document.execCommand('copy');
  }

  detectMobile() {
    if ( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true;
      } else {
        return false;
      }
  }
}
