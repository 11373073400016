import { XctBaseType } from '../types/xctBaseType';

export class OAuth2Client extends XctBaseType {

    client_id: number;
    name: string;
    website = '';

    constructor(defaultsHash: any = {}) {
        super();
        this.initializeFromDefaultsHash(defaultsHash);
    }

}
