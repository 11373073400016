import { AbstractControl } from '@angular/forms';
// import * as Debug_ from 'debug';
// const Debug = Debug_;
// const
// = Debug('shared:XctFormHelper');

import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { XctFormValidationService } from '../services/form_validation.service';

type XctViewState = 'hidden' | 'showing' | 'saving' | 'loading';

// Yikes, seems like extending core might be an interesting way to go. Turns out
// folks say that is Bad...and I took their word for it.
// export class XctFormHelper extends FormGroup {

// 'XctFormHelper' is a wrapper around a single Angular FormGroup. The intent is to centralize proprietary logic
// so that need not be repeated. For example, the "rules" for determining whether a form's SAVE button is active or not
// need only exist here and not be repeated for every single form wherever it exists.
export class XctFormHelper {
  formGroup: FormGroup;
  xctFormManager: XctFormManager;
  formIdentifier = '(no XctFormHelper debug identifier)'; // An arbitrary string like 'BankInfo' to uniquely identify form.
  formFieldsHash: any; // Gets passed to FormBuilder.group(...)
  paramsHash: any;
  showDebugOutput = false;
  xctViewState: XctViewState = 'hidden';
  savingMessageText: string;
  lastSavedTimestamp: number;

  xctFormValidationService: XctFormValidationService;

  functionName__FILE_DELETE: string;
  functionName__FILE_UPLOAD: string;
  functionName__FILE_DOWNLOAD: string;
  functionName__SAVE: string;
  functionName__SETUP: string;
  functionName__DISCARD: string;


  constructor(
    xctFormManager: XctFormManager,
    paramsHash: any = {}
  ) {
    const formHelper: any = this;
    formHelper.xctFormManager = xctFormManager;

    formHelper.xctFormValidationService = new XctFormValidationService();

    // Allow wildcards in paramsHash to override local defaults.
    Object.keys(paramsHash)
      .map(paramName => {
        // if ((<any>this)[paramName]) {
        formHelper[paramName] = (paramsHash as any)[paramName];
        // } else {
        //   console.error(`Unknown param passed to XctFormHelper: ${paramName}`, paramsHash);
        // }
      });

    // Retain copy of original params.
    formHelper.paramsHash = paramsHash;

    // Set fallback defaults for function names on *component* that will SETUP/SAVE
    // the form (e.g., 'form__BankInfo__SETUP', 'form__BankInfo__SAVE')
    const handlers = [
      'SETUP',
      'FILE_DELETE',
      'FILE_DOWNLOAD',
      'FILE_UPLOAD',
      'SAVE',
      'DISCARD',
    ];

    for (const handler of handlers) {
      const functionName_key = `functionName__${handler}`;
      if (!formHelper[functionName_key]) {
        formHelper[functionName_key] = `form__${formHelper.formIdentifier}__${handler}`;
      }
    }

    // FIXME(karl) use debug()
    if (formHelper.isDebug()) { console.log(`${formHelper.debugIdentifier()}init params`, paramsHash); }
  }

  debugIdentifier() {
    return `[Form: ${this.formIdentifier}] `;
  }

  // Handler the initial setup of a form before a FormGroup is created. The component's setup function is called to execute that logic.
  initial_SETUP(paramsHash: any = {}) {
    const xctFormHelper = this;
    const component = xctFormHelper.xctFormManager.parentComponent as any;
    const functionName = xctFormHelper.functionName__SETUP;
    const componentSETUPFunctionItself = (component)[functionName];

    if (this.isDebug()) { console.log(`${this.debugIdentifier()}initial_SETUP: functionName '${functionName}'`); }
    // console.warn(`${this.debugIdentifier()}initial_SETUP: functionName '${functionName}'`);

    this.setFormIsLoading();

    // Call the function in component that knows how to properly construct and populate this form with data.
    // This function looks something like, "component.form__BankInfo__SETUP".
    componentSETUPFunctionItself.apply(component, [xctFormHelper, paramsHash]);

    // We justs et up the form, it is good to go. But no one has asked to actually SEE the form, so make sure it starts HIDDEN.
    xctFormHelper.hideForm();
  }

  // smk: I tried *extending* and XctFormGroup on top of Angular FormGroup. Too many problems, so this returns a "normal"
  // FormGroup and tracks it inside of this XctFormHelper object instead.
  makeFormGroup(formFieldsHash: any = {}): FormGroup {

    // Use Angular's own FormBuilder to build the FormGroup object based on 'formFieldsHash' passed in.
    const formBuilder = new FormBuilder();
    const formGroup = formBuilder.group(formFieldsHash);

    this.formGroup = formGroup;

    // Retain copy of formFieldsHash for debugging.
    this.formFieldsHash = formFieldsHash;

    if (this.isDebug()) { console.log(`${this.debugIdentifier()}Make FormGroup from 'formFieldsHash'` +
      ` (${Object.keys(formFieldsHash).length} fields)`, formFieldsHash);
    }

    return formGroup;
  }

  hideForm() {
    this.xctViewState = 'hidden';
  }

  showForm() {
    this.xctViewState = 'showing';
  }

  setFormIsLoading() {
    this.xctViewState = 'loading';
  }

  setFormIsSaving() {
    this.xctViewState = 'saving';
  }

  // Static readout is represents the SAVED version of the form's data. This may or may not be present.
  isStaticReadoutHidden(): boolean {
    return (this.isFormShowing() || this.isFormLoading() || this.isFormSaving()) ? true : false;
  }

  isStaticReadoutShowing(): boolean {
    return !this.isStaticReadoutHidden();
  }

  // If form is *loading*, form is considered hidden.
  isFormHidden(): boolean {
    return this.xctViewState !== 'showing' ? true : false;
  }

  isFormLoadingOrSaving(): boolean {
    return (this.isFormLoading() || this.isFormSaving());
  }

  isFormSaving(): boolean {
    return this.xctViewState === 'saving' ? true : false;
  }

  isFormLoading(): boolean {
    return this.xctViewState === 'loading' ? true : false;
  }

  isFormShowing(): boolean {
    return this.xctViewState === 'showing' ? true : false;
  }

  // <div [hidden]="form_BankInfo__helper.isFormShowing()" class="xct-buttons">
  isEditTriggerHidden(): boolean {
    return this.isFormShowing();
  }

  // Use proprietary logic to determine if a field might have ANY errors to consider.
  isFieldError(fieldName: string): boolean {
    const formControl = this.getFormControlByName(fieldName);
    if (!formControl) {
      return false;
    }
    return !(formControl.valid || formControl.pristine);
  }

  // Use the FormGroup determination of whether a specific field has an error or not.
  hasSpecificFieldError(fieldName: string, errorName: string): boolean {
    const formControl = this.getFormControlByName(fieldName);
    return formControl.hasError(errorName);
  }

  // This bit is pretty much swiped from:
  //   https://coryrylan.com/blog/angular-2-form-builder-and-validation-management
  getSpecificField_errorsHash(fieldName: string): any {
    const formControl = this.getFormControlByName(fieldName);
    let errorsHash = {};

    if (!formControl) {
      return errorsHash;
    }

    errorsHash = formControl.errors;

    console.warn(`errorsHash for '${fieldName}'`, errorsHash);

    return errorsHash;
  }

  getSpecificField_errorsArray(fieldName: string): Array<any> {
    const formControl = this.getFormControlByName(fieldName);
    const errorsArray = new Array<any>();

    if (!formControl) {
      return errorsArray;
    }

    // console.warn(`formControl.errors for '${fieldName}'`, formControl.errors);

    // How to go in order of Angular's array? Want to reflect the priority of validations in order. But a hash does not do that.
    // A 'required' error should appear before a 'bad format' error. Hmm.
    for (const validatorName in formControl.errors) {
      if (formControl.errors.hasOwnProperty(validatorName) && formControl.touched) {
        const validatorValue = formControl.errors[validatorName];
        const hash = {
            errorCode: validatorName,
            errorTextRaw: validatorValue,
            errorText: this.xctFormValidationService.getValidatorErrorMessage(validatorName, validatorValue),
          };
        // this.getSpecificFieldErrorText()
        errorsArray.push(hash);
      }
    }

    // if (errorsArray.length > 0) console.warn(`errorsArray (length=${errorsArray.length}) for '${fieldName}'`, errorsArray);

    return errorsArray;
  }

  // This is not really functional yet. But maybe it'll add value at some point and can be fleshed out.
  // Problem is that Angular stores a form field's errors in a hash, not an ordered array. So it is tricky accessing
  // a prioritized list of the errors on a form field. Accessing only the first error helps alleviate bombarding
  // a user with many possible errors (e.g., problematic password) instead of the most relevant error in current context.
  getSpecificField_firstError(fieldName: string) {
    const errorsArray = this.getSpecificField_errorsArray(fieldName);
    return errorsArray.length > 0 ? errorsArray[0] : null;
  }

  // Get just the text for each error.
  // getSpecificField_allErrorTexts(fieldName: string): Array<string> {
  //   let
  //     formControl = this.getFormControlByName(fieldName),
  //     errorsArray = new Array<string>();
  //
  //   if (!formControl) {
  //     return errorsArray;
  //   }
  //
  //   // console.warn(`formControl.errors for '${fieldName}'`, formControl.errors);
  //
  //   // How to go in order of Angular's array? Want to reflect the priority of validations in order. But a hash does not do that.
  //   // A 'required' error should appear before a 'bad format' error. Hmm.
  //   for (let propertyName in formControl.errors) {
  //     if (formControl.errors.hasOwnProperty(propertyName) && formControl.touched) {
  //       let errorText = formControl.errors[propertyName]
  //       // this.getSpecificFieldErrorText()
  //       errorsArray.push(errorText);
  //     }
  //   }
  //
  //   console.warn(`errorsArray for '${fieldName}'`, errorsArray);
  //
  //   return errorsArray;
  // }

  getSpecificFieldErrorText(fieldName: string, errorName: string): string {
    const formControl = this.getFormControlByName(fieldName);
    let finalErrorText = '(error)';

    if (this.hasSpecificFieldError(fieldName, errorName)) {
      // This is probably the WRONG way to pluck out the error text, but I haven't found
      // anything better than reaching in to Angular's object.
      const errorText = formControl.errors[errorName];
      if (errorText.length) {
        finalErrorText = errorText;
      }
    }

    return finalErrorText;
  }

  // <button (click)="form_BankAccount__triggerClose('save')" [disabled]="
  // form_BankAccount.pristine || !form_BankAccount.valid" class="btn btn-primary btn-sm">Save</button>
  isSaveButtonDisabled(): boolean {
    const formGroup = this.formGroup;
    // console.info(`formGroup.pristine=${formGroup.pristine}; formGroup.valid=${formGroup.valid}`);
    return (formGroup.pristine || !formGroup.valid);
  }

  isSaveButtonEnabled(): boolean {
    return !this.isSaveButtonDisabled();
  }

  isDiscardButtonDisabled(): boolean {
    const formGroup = this.formGroup;
    // console.info(`formGroup.pristine=${formGroup.pristine}; formGroup.valid=${formGroup.valid}`);
    return (formGroup.pristine);
  }

  isDiscardButtonEnabled(): boolean {
    return !this.isDiscardButtonDisabled();
  }

  // Determines whether debug output will print to console for this form.
  // Allow Manager to squelch all debug output (thereby overriding this helper's own setting).
  isDebug(): boolean {
    return (this.showDebugOutput && !this.xctFormManager.quietAllDebugOutput);
  }

  // Public hook. Maybe useful to keep separate.
  getControl(controlName: string): FormControl {
    return this.getFormControlByName(controlName) as FormControl;
  }

  getFormGroup(): FormGroup {
    return this.formGroup;
  }

  // Reveal the form fields so they can be edited. Hide any other open forms on the page.
  trigger__EDITING() {
    for (const xctFormHelper of this.xctFormManager.getAllHelpers()) {
      xctFormHelper.hideForm();
    }
    this.showForm();
  }

  // Close but do NOT discard changes.
  trigger__CLOSE(): boolean {

    // CLose ALL open forms.
    for (const xctFormHelper of this.xctFormManager.getAllHelpers()) {
      xctFormHelper.hideForm();
    }

    // Open only this CURRENT form so that it is the only one open.
    this.showForm();

    return false;
  }

  // Close AND discard changes.
  trigger__DISCARD(): boolean {
    // debug(`${this.debugIdentifier()}'trigger__DISCARD'`);

    // Close ALL open forms.
    for (const helper of this.xctFormManager.getAllHelpers()) {
      helper.hideForm();
    }

    const simpleHash = this.getHashOfInitializationKeysAndValues();

    // See: https://angular.io/docs/ts/latest/api/forms/index/FormGroup-class.html#!#reset-anchor
    this.formGroup.reset(simpleHash);

    // Optional hook for custom discard code if necessary.
    const xctFormHelper = this;
    const component = xctFormHelper.xctFormManager.parentComponent as any;
    const functionName = xctFormHelper.functionName__DISCARD;
    const componentDISCARDFunctionItself = (component)[functionName];

    this.clearFormMessages();

    if (componentDISCARDFunctionItself) {
      // console.warn('execute componentDISCARDFunctionItself');
      // debug(`${this.debugIdentifier()}initial_DISCARD: functionName '${functionName}'`);

      // Call the function in component that knows how to properly construct and populate this form with data.
      // This function looks something like, "component.form__BankInfo__DISCARD".
      componentDISCARDFunctionItself.apply(component, [xctFormHelper]);
    }

    // Must return FALSE to terminate submit event and keep the form submit handler from firing.
    return false;
  }

  clearFormMessages(paramsHash: any = {}): void {
    const xctFormHelper = this;
    const component = xctFormHelper.xctFormManager.parentComponent as any;

    // Reset any common messages.
    if (component.xctFormErrorText) {
      component.xctFormErrorText = null;
    }

    if (component.xctFormSuccessText) {
      component.xctFormSuccessText = null;
    }
  }

  // Save the form data to server. Introspection logic lives in component, not here. This is a wrapper around that call.
  trigger__SAVE(paramsHash: any = {}): boolean {
    const xctFormHelper = this;
    let didSaveTakePlace = false;
    const component = xctFormHelper.xctFormManager.parentComponent as any;
    const functionName = xctFormHelper.functionName__SAVE;
    const componentFunction = (component)[functionName];
    const formGroup = this.formGroup;

    // console.warn(`${this.debugIdentifier()}'trigger__SAVE': paramsHash`, paramsHash);

    if (formGroup.pristine) {
      console.error(`${this.debugIdentifier()}'trigger__SAVE', but the formGroup is PRISTINE?`);
      return didSaveTakePlace;
    } else if (!formGroup.valid) {
      console.error(`${this.debugIdentifier()}'trigger__SAVE', but the formGroup is NOT valid, so we should not be here?`);
      return didSaveTakePlace;
    } else if (this.lastSavedTimestamp && (new Date().getTime() - this.lastSavedTimestamp < 400)) {
      // # Sometimes this case comes up of rapid form submission, seems to be programmatic error, not sure where yet.
      // console.error(`${this.debugIdentifier()}'trigger__SAVE', but double form submit, so skip second submission`);
      return didSaveTakePlace;
    }

    // debug(`${this.debugIdentifier()}'trigger__SAVE';` +
    //   ` theForm.valid=${formGroup.valid}; formFieldsHash dump`, this.formFieldsHash);

    // Invoke the function in component that is responsible for actually saving the form.
    const outboundFlattenedFieldsHash = componentFunction.apply(component, [xctFormHelper, formGroup, paramsHash]);
    didSaveTakePlace = true;
    this.lastSavedTimestamp = new Date().getTime();

    // debug(`${this.debugIdentifier()}'trigger__SAVE';` +
    //   ` outboundFlattenedFieldsHash dump`, outboundFlattenedFieldsHash);

    // Any post processing.
    xctFormHelper.setFormIsSaving();

    return didSaveTakePlace;
  }

  // This hook for handling file upload gets called TWICE:
  //  1) The FIRST time is immediate and it does NOT yet have a 'response'. This is useful for triggering a "loading" indicator.
  //  2) The SECOND time is when it *does* have a response from API.
  trigger__FILE_UPLOAD(document: Document, uploadedFileInfoHash: any, paramsHash: any = {}): void {
    const xctFormHelper = this;
    const component = xctFormHelper.xctFormManager.parentComponent as any;
    const functionName = xctFormHelper.functionName__FILE_UPLOAD; // Will look something like, "form__LegalDocuments__FILE_UPLOAD"
    const componentFunction = (component)[functionName];
    let isUploadCompleted: boolean;

    // debug(`trigger__FILE_UPLOAD: Uploading ${uploadedFileInfoHash['originalName']}`,
    //   uploadedFileInfoHash, document);

    // This is NOT an error! File is *process* of uploading (<or> server has not otherwise responded?).
    if (!(uploadedFileInfoHash && uploadedFileInfoHash.response)) {
      isUploadCompleted = false;
      // debug(`Could not identify 'uploadedFileInfoHash.response'...so that means `
      //   + `file (${uploadedFileInfoHash['originalName']}) is uploading now`, uploadedFileInfoHash);
    } else {
      isUploadCompleted = true;
      // debug(`Document`, document);

      // Response should always be JSON...but if it happens to be plain text (e.g., "Internal Server Error"), catch that.
      let decodedResponse: any = {};
      try {
        // API response should be JSON.
        decodedResponse = JSON.parse(uploadedFileInfoHash.response);
        // console.info(`smk Ng2Uploader__handleFileUpload response`, decodedResponse);
      } catch (e) {
        console.error(`Error parsing JSON info for file from server response...` +
          `'uploadedFileInfoHash.response' unparsable text was ${uploadedFileInfoHash.response}`, uploadedFileInfoHash);
      }

      // A message here indicates an error.
      paramsHash.serverResponse = decodedResponse;
      if (decodedResponse.message) {
        console.error(`smk Ng2Uploader__handleFileUpload error message `
          + `from server for user: "${decodedResponse.message}"`, decodedResponse);
        paramsHash.serverErrorText = decodedResponse.message;
      } else if (decodedResponse.result && decodedResponse.result.data) {
        // Upload was successfully saved.
        const newServerRecord = decodedResponse.result.data;
        paramsHash.serverNewRecord = newServerRecord;
        // debug(`smk Ng2Uploader__handleFileUpload new Document record`, newServerRecord);
      } else {
        // Error case. [not really error case]
        // console.error(`smk Ng2Uploader__handleFileUpload failed to return Document record?`, decodedResponse);
      }
    }

    // Call instantiating component's handler that will know how to actually manage this uploaded file.
    // Do this in all cases as there may be a local progress or loading indicator.
    componentFunction.apply(component, [xctFormHelper, document, uploadedFileInfoHash, isUploadCompleted, paramsHash]);
  }


  // trigger__FILE_DELETE_wrapperFunction(document: any, paramsHash: any = {}) {
  //   let theFunction = function(){
  //     console.info('aasssa');
  //   }.bind();
  // }

  // Bummer that to download a file, there is no generic solution. Different API endpoints required for
  // admin/publisher/user. Let caller deal with it.
  trigger__FILE_DOWNLOAD(document: Document, paramsHash: any = {}): void {
    const xctFormHelper = this;
    const component = xctFormHelper.xctFormManager.parentComponent as any;
    const functionName = xctFormHelper.functionName__FILE_DOWNLOAD;
    const componentFunction = (component)[functionName];

    if (xctFormHelper.isDebug()) { console.warn(`xctFormHelper.trigger__FILE_DOWNLOAD: ${functionName}`, component); }

    componentFunction.apply(component, [xctFormHelper, document, paramsHash]);
  }

  trigger__FILE_DELETE(document: any, paramsHash: any = {}) {
    const xctFormHelper = this;
    const component = xctFormHelper.xctFormManager.parentComponent as any;
    const functionName = xctFormHelper.functionName__FILE_DELETE;
    const componentFunction = (component)[functionName];

    if (xctFormHelper.isDebug()) { console.warn(`xctFormHelper.trigger__FILE_DELETE`, component); }

    componentFunction.apply(component, [xctFormHelper, document, paramsHash]);
  }

  private getHashOfInitializationKeysAndValues(): any {
    const simpleHash: any = {};

    // 'formFieldsHash' is exactly what was originally furnished for FormGroup. We need to get at the *value*
    // and not include validators that are included in that array.
    Object.keys(this.formFieldsHash)
      .map(key => {
        const array = this.formFieldsHash[key];
        simpleHash[key] = (array as any)[0];
      });
    return simpleHash;
  }

  private getFormControlByName(fieldName: string): AbstractControl {
    const formControl = this.formGroup.controls[fieldName];
    if (!formControl) {
      console.error(`${this.debugIdentifier()}Field '${fieldName}' is not defined in this form.`, this.formFieldsHash);
      return null;
    }
    return formControl;
  }

}

// 'XctFormManager' manages multiple 'XctFormHelper' objects that coexist on one page. This makes it easier to, for example,
// setup all forms in one swoop, or ensure all forms are closed.
export class XctFormManager {
  parentComponent: Component;
  paramsHash: any;
  quietAllDebugOutput = false;
  xctFormHelpers: Map<string, XctFormHelper> = new Map<string, XctFormHelper>();

  constructor(paramsHash: any = {}) {
    Object.keys(paramsHash)
      .map(paramName => {
        // if ((<any>this)[paramName]) {
        (this as any)[paramName] = (paramsHash as any)[paramName];
        // } else {
        //   console.error(`Unknown param passed to XctFormHelper: ${paramName}`, paramsHash);
        // }
      });

    // Retain copy of original params.
    this.paramsHash = paramsHash;

    if (!paramsHash.formDefinitions) {
      console.error(`Must provide 'formDefinitions' to XctFormManager`, paramsHash);
      return;
    }
    // formIdentifier
    // var x: Map<number, string> = new Map<number, string>();
    for (const helperDefinitionHash of paramsHash.formDefinitions) {
      const xctFormHelper = new XctFormHelper(this, helperDefinitionHash);
      const formIdentifier = xctFormHelper.formIdentifier;

      (this.xctFormHelpers as any)[formIdentifier] = xctFormHelper;
    }

    // if (this.isDebug()) console.info(`${this.debugIdentifier()}init params`, paramsHash);
  }

  // debugIdentifier() {
  //   // return `[Form: ${this.formIdentifier}] `;
  // }

  getAllHelpers(): Array<XctFormHelper> {
    const array: Array<XctFormHelper> = [];

    Object.keys(this.xctFormHelpers)
      .map(key => {
        const xctFormHelper = (this.xctFormHelpers as any)[key];
        array.push(xctFormHelper);
      });

    return array;
  }

  getHelper(formIdentifier: string): XctFormHelper {
    const xctFormHelper = (this.xctFormHelpers as any)[formIdentifier];
    if (!xctFormHelper) {
      console.error(`No xctFormHelper with identifier, '${formIdentifier}'.`);
    }
    return xctFormHelper;
  }

  // This should only be called if the caller *expects* that this XctFormManager has ONE and only one xctFormHelper.
  // This is useful to facilitate shortcuts in components only dealing with a single form.
  getSoloHelper(): XctFormHelper {
    const allHelpers = this.getAllHelpers();
    const count = allHelpers.length;
    if (count !== 1) {
      console.error(`xctFormHelper count != 1? '${count}'.`);
    }
    return allHelpers[0];
  }

  getFormGroup(formIdentifier: string): FormGroup {
    const xctFormHelper = this.getHelper(formIdentifier);
    return xctFormHelper.formGroup;
  }

  setupAllForms(paramsHash: any = {}) {
    for (const xctFormHelper of this.getAllHelpers()) {
      xctFormHelper.initial_SETUP(paramsHash);
    }
  }

  // getCountOfHelpers(): number {
  //   return this.getAllHelpers().length;
  // }

}
