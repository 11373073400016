<canvas
  baseChart
  [data]="pieChartData"
  [height]="180"
  [labels]="pieChartLabels"
  [type]="pieChartType"
  [options]="pieChartOptions"
  [plugins]="pieChartPlugins"
  [legend]="pieChartLegend"
>
</canvas>
