import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export interface Country {
  code: string;
  region: string;
  name: string;
}

// Source:
// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2

@Injectable()
export class CountryService {
    public countries: Country[];

    constructor() {
      const countryData = [
        { code: 'AU', region: 'ASIA', name : 'Australia' },
        { code: 'AT', region: 'EUROPE',  name : 'Austria' },
        { code: 'BE', region: 'EUROPE',  name : 'Belgium' },
        { code: 'BR', region: 'S_AMERICA',  name : 'Brazil' },
        { code: 'CA', region: 'N_AMERICA',  name : 'Canada' },
        { code: 'DK', region: 'EUROPE',  name : 'Denmark' },
        { code: 'DE', region: 'EUROPE',  name : 'Germany' },
        { code: 'FI', region: 'EUROPE',  name : 'Finland' },
        { code: 'FR', region: 'EUROPE',  name : 'France' },
        { code: 'HK', region: 'ASIA',  name : 'Hong Kong' },
        { code: 'IE', region: 'EUROPE',  name : 'Ireland' },
        { code: 'IT', region: 'EUROPE',  name : 'Italy' },
        { code: 'JP', region: 'ASIA',  name : 'Japan' },
        { code: 'LU', region: 'EUROPE',  name : 'Luxembourg' },
        { code: 'MX', region: 'N_AMERICA',  name : 'Mexico' },
        { code: 'NL', region: 'EUROPE',  name : 'Netherlands' },
        { code: 'NZ', region: 'ASIA',  name : 'New Zealand' },
        { code: 'NO', region: 'EUROPE',  name : 'Norway' },
        { code: 'PT', region: 'EUROPE',  name : 'Portugal' },
        { code: 'SG', region: 'ASIA',  name : 'Singapore' },
        { code: 'ES', region: 'EUROPE',  name : 'Spain' },
        { code: 'SE', region: 'EUROPE',  name : 'Sweden' },
        { code: 'CH', region: 'EUROPE',  name : 'Switzerland' },
        { code: 'UK', region: 'EUROPE',  name : 'United Kingdom' },
      ];
      this.countries = _.sortBy(countryData, ['name']);
      this.countries.unshift({ code: 'US', region: 'US', name: 'United States' });
    }
}
