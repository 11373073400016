import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-validation-message',
  template: `<span *ngIf="display" [ngClass]="position ? 'position-' + position : ''"
    ><ng-content></ng-content
  ></span>`,
})
export class ValidationMessageComponent {
  @Input() displayOn: string;
  @Input() position: any = '';
  display = false;
}
