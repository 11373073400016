import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

export type CurrencyPrecisions = 'cent' | 'hundredth' | 'hundredth_c' | 'no_round';

@Pipe({ name: 'xsactCurrency' })
export class XsactTokensToUSDPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: number | string, currencyCode?: string, valuePrecision?: CurrencyPrecisions, digitsInfo?: string, locale?: string
  ): string | null;
  transform(
    value: null | undefined, currencyCode?: string, valuePrecision?: CurrencyPrecisions, digitsInfo?: string, locale?: string
  ): null;
  transform(
    value: number | string | null | undefined,
    currencyCode?: string,
    valuePrecision?: CurrencyPrecisions,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if(!currencyCode || currencyCode === 'PROD') {
      currencyCode = 'USD';
    }
    const parsedValue = typeof value === 'string' ? parseInt(value, 10) : value;
    let printValue = valuePrecision === 'cent' ? parsedValue
      : valuePrecision === 'hundredth' ? Math.floor(parsedValue / 100)
      : valuePrecision === 'hundredth_c' ? Math.ceil(parsedValue / 100)
      : parsedValue;

    if (
      valuePrecision !== 'no_round' &&
      currencyCode !== 'JPY'
    ) {
      printValue = printValue / 100;
    }

    return super.transform(printValue, currencyCode, 'symbol', digitsInfo || '1.2-2', locale);
  }
}
