<!-- This does NOT work for 'ngSwitch' and is a known issue in Angular 2...don't wrap ngSwitch in 'template'...must be something else:
  <ng-template  [ngSwitch]="this.getOption('displayFormat')">
-->
<div [ngSwitch]="getOption('displayFormat')" [ngClass]="{'xct-list-first': isThisTheFirstItemInList(), 'xct-list-last': isThisTheLastItemInList() }" class="collapse-left xct-format-{{ getOption('displayFormat') }}">

  <ng-template  [ngSwitchCase]="'default'">
    <div class="media">
    <div class="media-left">
      <a *ngIf="getImageUrl()" href="#">
        <img class="media-object" src="{{ getImageUrl() }}" class="xct-img-max-50">
      </a>
    </div>

    <div class="media-body">
      <h4 class="media-heading"><a href="{{ transaction.url }}" class="xct-website-url" target=_blank>{{ transaction.url }}</a><span *ngIf="options.isDebug" class="xct-debug-text"> [{{ transaction.id }}]</span></h4>
      <div class="xct-timestamp">{{ transaction.timestamp | date: 'MM/dd/yy HH:mm' }}</div>
      <div class="xct-credits-count"><i class="fa fa-angle-right"></i>{{ this.getCreditsCountText() }}</div>

      <div *ngIf="options.isDebug" class="xct-debug-json">{{ transaction | json }}</div>
    </div>
    </div>
  </ng-template >

  <!-- This was nice idea, but Angular 2 breaks entirely the notion of having a row as a component. Maybe another day.  -->
  <ng-template  [ngSwitchCase]="'tableRow3Col'">
      <div class="xct-table-cell">
        <div class="xct-timestamp">{{ transaction.timestamp | date: 'MM/dd/yy HH:mm' }}</div>
      </div>
      <div class="xct-table-cell">
        action {{ transaction.url }}
        <div *ngIf="options.isDebug" class="xct-debug-json">{{ transaction | json }}</div>
      </div>
      <div class="xct-table-cell">
        amount
      </div>
  </ng-template >

  <ng-template  ngSwitchDefault>
    <p>Unidentified 'displayFormat'={{ getOption('displayFormat') }}</p>
  </ng-template >

</div>

<!--

  <div class="xct-table-cell">
    <div class="xct-timestamp">{{ transaction.timestamp | date: 'MM/dd/yy HH:mm' }}</div>
  </div>
  <div class="xct-table-cell">
    action {{ transaction.url }}
    <div *ngIf="options.isDebug" class="xct-debug-json">{{ transaction | json }}</div>
  </div>
  <div class="xct-table-cell">
    amount
  </div>
</div>

-->
