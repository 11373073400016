import { XctBaseType } from './xctBaseType';
import { Address } from './address';

type BankAccountType = 'ACH' | 'SWIFT' | 'IBAN';

export class Bank extends XctBaseType {
  idx = 0;  // index in a list for multiple banks in the future.

  account_number_last4 = ''; // READ only, sever sets this.
  account_number = ''; // account number
  address: Address = new Address();
  contact_email = '';
  contact_name = ''; // Contact name at bank
  contact_phone = ''; // Contact phone number
  name = '';  // name of bank
  routing_number = '';  // routing number to bank
  swift = '';  // swift
  updated = 0; // timestamp
  type: BankAccountType = null;

  constructor(defaultsHash: any = {}) {
    super();
    const newObject = this.initializeFromDefaultsHash(defaultsHash);

    // Fields based on other classes that are not plain Javascript values.
    Object.keys(defaultsHash)
      .map(defaultKey => {
        const defaultValue = defaultsHash[defaultKey];
        if (defaultKey === 'address') {
          newObject[defaultKey] = new Address(defaultValue);
        }
      });

    this.idx = this.idx || 0;
  }

}
