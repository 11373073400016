import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appLoadingState]',
})
export class ButtonLoadingStateDirective implements OnInit, OnChanges {
  @Input() loadingState: boolean;
  @Input() loadingOnClick: boolean;
  element: any;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('click', ['$event.target'])
  public onClick(targetElement: any) {
    if (!targetElement.classList.contains('loading') && this.loadingOnClick) {
      this.changeButtonState(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadingState && this.element) {
      this.changeButtonState(changes.loadingState.currentValue);
    }
  }

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.renderer.setStyle(this.element, 'flex-direction', 'row-reverse');
    this.renderer.setStyle(this.element, 'justify-content', 'center');
    this.renderer.setStyle(this.element, 'align-items', 'center');
    this.renderer.setStyle(this.element, 'display', 'inline-flex');

    if (this.element) {
      this.updateElement();
    }
  }

  updateElement() {
    const spinnerNode = document.createElement('span');
    spinnerNode.style.display = 'none';
    spinnerNode.style.margin = '0 7px 0 0';
    spinnerNode.className = 'fa fa-refresh fa-spin';
    this.element.appendChild(spinnerNode);
  }

  changeButtonState(state: boolean) {
    const buttonSpinner = this.element.querySelector('.fa-spin');

    if (buttonSpinner) {
      buttonSpinner.style.display = state === true ? 'inline-block' : 'none';

      if (state === true) {
        this.renderer.setAttribute(this.element, 'disabled', 'true');
      } else {
        this.renderer.removeAttribute(this.element, 'disabled');
      }
    }
  }
}
