import { XctBaseType } from './xctBaseType';
import { Validators } from '@angular/forms';

export class Address extends XctBaseType {
  line1 = ''; // address line1
  line2 = ''; // address line2
  city = '';
  region = ''; // US State or region, teritory
  postal_code = ''; // Zip Code / postal code
  country = '';  // Two letter ISO country code

  validCountryCodes = [
    'AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BH', 'BS', 'BD',
    'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'KH',
    'CM', 'CA', 'CV', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU',
    'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR',
    'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY',
    'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO',
    'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MK', 'MG',
    'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ',
    'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA',
    'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM',
    'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES',
    'LK', 'SD', 'SR', 'SJ', 'SZ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR',
    'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE',
    'ZM', 'ZW'
  ];

  constructor(defaultsHash: any = {}) {
    super();
    const newObject: any = this;
    if (Object.keys(defaultsHash).length) {
      Object.keys(defaultsHash)
        .filter(key => (key in newObject))
        .map(key => {
          const defaultValue: any = defaultsHash[key];
          newObject[key] = defaultValue;
        });
    }

    // must have something here to avoid empty warning
    this.city = this.city || '';
  }

  // This function does not appear to be used anywhere
  isValid(): boolean {
    let valid = true;
    if (this.validCountryCodes.indexOf(this.country) === -1) {
      valid = false;
    }
    return valid;
  }

  public hasStreetInfo(): boolean {
    return this.line1.length > 0 ? true : false;
  }


  public getFormFieldsHashForObject(paramsHash: any = {}) {

    const objectToUse: any = paramsHash.objectToUse || this;
    // let isDataPresent = Object.keys(objectToUse).length ? true : false;
    const isDataPresent = true;  // Use defaults in class definition above for now. That may not always be the case.

    const formFieldsHash: any = {
      line1: [isDataPresent ? objectToUse.line1 : '', [Validators.required]],
      line2: [isDataPresent ? objectToUse.line2 : '', []],
      city: [isDataPresent ? objectToUse.city : '', [Validators.required]],
      country: [isDataPresent ? objectToUse.country : 'US', [Validators.required]],
      region: [isDataPresent ? objectToUse.region : '', [Validators.required]],
      postal_code: [isDataPresent ? objectToUse.postal_code : '', [Validators.required]],
    };

    if (!paramsHash.skipCountry) {
      // Default to US for now? No pulldown menu?
      // country: [isDataPresent ? objectToUse.country : '', [Validators.required]],
      formFieldsHash.country = [isDataPresent && objectToUse.country ? objectToUse.country : 'US', [Validators.required]];
    }

    // console.warn(`getFormFieldsHashForObject`, isDataPresent, formFieldsHash);

    return formFieldsHash;
  }

  // 'paramsHash' can force additional validators for a given use case. Maybe city/region are NOT required in certain places.
  // public makeFormGroupForObject(formBuilder: FormBuilder, paramsHash: any = {}) {
  //   // let formBuilder = new FormBuilder();
  //   // this.formBuilder
  //
  //   let objectToUse: any = paramsHash.objectToUse || this;
  //   // let isDataPresent = Object.keys(objectToUse).length ? true : false;
  //   let isDataPresent = true;  // Use defaults in class definition above for now. That may not always be the case.
  //
  //   let formGroupHash: any = {
  //     line1: [isDataPresent ? objectToUse.line1 : '', [Validators.required]],
  //     line2: [isDataPresent ? objectToUse.line2 : '', []],
  //     city: [isDataPresent ? objectToUse.city : '', [Validators.required]],
  //     region: [isDataPresent ? objectToUse.region : '', [Validators.required]],
  //     postal_code: [isDataPresent ? objectToUse.postal_code : '', [Validators.required]],
  //   };
  //
  //   if (!paramsHash.skipCountry) {
  //     // Default to US for now? No pulldown menu?
  //     // country: [isDataPresent ? objectToUse.country : '', [Validators.required]],
  //     formGroupHash.country = [isDataPresent ? objectToUse.country : 'US', []];
  //   }
  //
  //   let formGroup = formBuilder.group(formGroupHash);
  //
  //   // console.warn(`makeFormGroup`, isDataPresent, formGroup);
  //
  //   return formGroup;
  // }

}
