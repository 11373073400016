// import * as Debug_ from 'debug';
// const Debug = Debug_;
// const debug = Debug('shared:TransactBackend');
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
 } from '@angular/common/http';

import { Observable, of, throwError  } from 'rxjs';
import { tap } from 'rxjs/operators';
import { concatMap, delay, retryWhen  } from 'rxjs/operators';

let auth = '';

export const setAuthToken = (token: string, stayLoggedIn?: boolean) => {
  auth = token;

  try {
    sessionStorage.setItem('authorization', token);
    if (!localStorage.getItem('successLogin')) {
      localStorage.setItem('successLogin', 'true');
    }

    sessionStorage.setItem('authorization', token);

    if (stayLoggedIn) {
      localStorage.setItem('authorization', token);
    }
  } catch (e) {
    console.warn('Localstorage not available. User will be logged out on window close.');
  }
};

@Injectable()
export class TransactBackendInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    if (req.url === '/api/user/logout') {
      this.clearAuthToken();
    }

    if (req.url.startsWith('/api/')) {
      const authToken = this.checkAuthToken();

      const serverReq = req.clone({
        headers: req.headers.set('Authorization', authToken),
        url: req.url,
        withCredentials: true
      });
      const max_retries = 3;
      const retry_wait_ms = 100;

      return next.handle(serverReq).pipe(
        retryWhen(error =>
          error.pipe(
            concatMap((error, count) => {
              if (error && error.status >= 400 && error.status <= 499) {
                // don't retry 4XX errors
                return throwError(error);
              }

              if (count <= max_retries) {
                // retry any other kind of error
                return of(error);
              }
              return throwError(error);

            }),
            delay(retry_wait_ms)
          )
        ),
        tap((event: HttpEvent<any>) => {
          // console.log(event);
        }, (err: any) => {
          const oauthPage = this.router.url.startsWith('/oauth2');
          const loginPage = this.router.url.startsWith('/login');
          const authReq = req.url.endsWith('authenticate');

          if (!oauthPage && !loginPage && !authReq && err instanceof HttpErrorResponse && err.url) {
            if (err.status === 401) {
              // not logged in on server but logged in on app
              // this.curUserService.logout(true);
              this.router.navigate(['user', 'signout']);
            }
            // if (err.status < 100 || err.status >= 500) {
            //   if (err.url.endsWith('/user/profile')) { // probably can't access api
            //     this.router.navigate(['error', 'api']);
            //   }
            // }
          }
        }));
    } else {
      return next.handle(req);
    }

  }

  private clearAuthToken() {
    auth = '';
  }

  private checkAuthToken() {
    if (!auth) {
      try {
        if (sessionStorage.getItem('authorization')) {
          // Restore the contents of the text field
          auth = sessionStorage.getItem('authorization');
        } else if (localStorage.getItem('authorization')) {
          // Restore the contents of the text field
          auth = localStorage.getItem('authorization');
        }
      } catch (e) {
        return '';
      }
    }

    return auth;
  }
}

/*



@Injectable()
export class TransactBackend extends HttpClient {

  constructor(protected _backend: ConnectionBackend, protected _defaultOptions: RequestOptions) {
    super(_backend, _defaultOptions);

    debug('TransactBackend Config:', Config);
  }

  //Override each type of HTTP request we are going to use
  request(url: string | Request, options?: RequestOptionsArgs): Observable<HttpResponse<any>> {

    if (typeof url === 'string') {
      debug('request url string...', url);
      return super.request(this._ApiUrl(url), options);
    }

    debug('request...', url);
    url.url = this._ApiUrl(url.url);
    return super.request(url, options);
  }

  get(url: string, options?: RequestOptionsArgs): Observable<HttpResponse<any>> {
    //debug('get...', url);
    return super.get(this._ApiUrl(url), options);
  }

  // Performs a request with `post` http method.
  post(url: string, body: string, options?: RequestOptionsArgs): Observable<HttpResponse<any>> {
    //debug('post...', url);
    return super.post(this._ApiUrl(url), body, options);
  }


  // Performs a request with `put` http method.
  put(url: string, body: string, options?: RequestOptionsArgs): Observable<HttpResponse<any>> {
    //debug('put...', url);
    return super.put(this._ApiUrl(url), body, options);
  }


  // Performs a request with `delete` http method.
  delete (url: string, options?: RequestOptionsArgs): Observable<HttpResponse<any>> {
    return super.delete(this._ApiUrl(url), options);
  }


  // Performs a request with `patch` http method.
  patch(url: string, body: string, options?: RequestOptionsArgs): Observable<HttpResponse<any>> {
    return super.patch(this._ApiUrl(url), body, options);
 }

  // Performs a request with `head` http method.
  head(url: string, options?: RequestOptionsArgs): Observable<HttpResponse<any>> {
    return super.head(this._ApiUrl(url), options);
  }

  private _ApiUrl(url: string): string {

    // if we should prefix our API server
    if (url.startsWith('/api'))
      return Config.API + url;

    return url;
  }

}

export function TransactBackendFactory(backend: ConnectionBackend,
  defaultOptions: RequestOptions) {

  defaultOptions.withCredentials = true;
  return new TransactBackend(backend, defaultOptions);
}

*/
