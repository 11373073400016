<h3 class="tt__subpage-title">Discount Codes</h3>

<div class="tt__m-t-15" *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout" [dismissible]="true">
        <span [innerHTML]="alert.msg"></span>
    </alert>
</div>

<div class="tt__panel-box code-generation">
    <div class="header">
        <h3>Generate your discount codes</h3>
    </div>
    
    <div class="info-wrapper info-left" *ngIf="noSubscriptionSetup">
        <i class="fa fa-info-circle"></i>
        <p>
            Discount codes allow your account to set a percentage discount on subscriptions. 
            To enable subscriptions your site must use the <a [routerLink]="['/publisher/subscriptions']" title="Publisher Subscriptions">publisher subscriptions feature.</a>
        </p>
      </div>
    <div class="body" *ngIf="!noSubscriptionSetup">
        <div class="code-step step-0"
            [ngClass]="{'disabled': selectedStep !== 0}">
            <h2 class="title-header" (click)="setStep(0)">
                <span class="number">1</span>
                What publisher would you like this code to be used with
            </h2>
            <div class="step-content">
                <div class="spinner publishers-loader" *ngIf="loadingPublishers">
                    <span class="fa fa-refresh fa-spin big"></span>
                    <span class="label">Loading publishers list...</span>
                </div>
                <div class="elements-list"
                    *ngIf="!loadingPublishers"
                    [ngClass]="{'disabled': false}">
                    <div class="element-wrapper"
                        *ngFor="let publisher of redemptionPublishers">
                        <div class="element"
                            [ngClass]="{'selected': publisher.selected}">
                            <app-publisher-banner-image [publisher]="publisher"></app-publisher-banner-image>
                            <span class="details">
                                <h3>{{ publisher.org }}</h3>
                                <a [href]="publisher.website"
                                    class="link"
                                    target="_blank"
                                [title]="publisher.website">{{ publisher.website }}</a>
                            </span>
                            <span class="actions">
                                <button
                                    class="tt--button button--blue large"
                                    [ngClass]="{'button--red': publisher.selected}"
                                    (click)="publisher.selected ? selectPublisher() : selectPublisher(publisher)">
                                        {{ publisher.selected ? 'Cancel' : 'Select'}}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="code-step step-1"
            [ngClass]="{'disabled': selectedStep !== 1}">
            <h2 class="title-header" (click)="setStep(1)">
                <span class="number">2</span>
                What subscription can this code be used with.
            </h2>
            <div class="step-content info" *ngIf="!isPublisherSelected()">
                <div class="info-wrapper info-left">
                    <i class="fa fa-info-circle"></i>
                    <p>
                        You didn't select any publishers. Please select one of the publisher from your list before selecting the subscription.
                    </p>
                </div>
            </div>
            <div class="step-content" *ngIf="isPublisherSelected()">
                <div class="subscriptions-list"
                    *ngFor="let publisher of redemptionPublishers" [hidden]="!publisher.selected">
                    <div class="subscription">
                        <div class="details">
                            <strong>All subscriptions</strong>
                        </div>
                        <button
                            (click)="publisher.checked ? selectAllSubsPublisher() : selectAllSubsPublisher(publisher)"
                            [ngClass]="{'button--white': publisher.checked}"
                            class="tt--button button--black large">
                                {{ publisher.checked ? 'Discard' : 'Select All'}}
                            </button>
                    </div>
                    <!-- <div class="subscription set-all-subs"
                        [ngClass]="{'checked': publisher.checked}"
                        (click)="publisher.checked ? selectAllSubsPublisher() : selectAllSubsPublisher(publisher)">
                        {{ publisher.checked ? 'Discard changes' : 'Set code for all subscriptions'}}
                    </div> -->
                    <div class="subscription"
                        [ngClass]="{'selected': s.selected, 'checked': publisher.checked}"
                        *ngFor="let s of publisher.subscriptions">
                        <div class="details">
                            <span class="price">{{ s.token_price | xsactCurrency:s.token_class:'cent' }}</span>
                            <span class="separator">per</span>
                            <span class="type">{{ displayPlan(s.plan_type) }}</span>
                        </div>
                        <button
                            (click)="s.selected ? selectSubscription() : selectSubscription(s)"
                            [ngClass]="{'button--white': s.selected}"
                            class="tt--button button--black large">
                                {{ s.selected ? 'Discard' : 'Set Code'}}
                            </button>
                    </div>
                </div>
                <div class="info-wrapper info-left">
                    <i class="fa fa-info-circle"></i>
                    <p>
                        You can select one of the listed subscriptions or you can select
                        publisher and add discount code for all subscriptions that belongs to them.
                    </p>
                </div>
            </div>
        </div>
        <div class="code-step step-2"
            [ngClass]="{'disabled': selectedStep !== 2}">
            <h2 class="title-header" (click)="setStep(2)">
                <span class="number">3</span>
                Would you like to generate a single code, or a batch of codes?
            </h2>
            <div class="step-content info" *ngIf="!isSubscriptionSelected()">
                <div class="info-wrapper info-left">
                    <i class="fa fa-info-circle"></i>
                    <p>
                        You didn't select any subscription. Please select one of the subscriptions.
                    </p>
                </div>
            </div>
            <div class="step-content" *ngIf="isSubscriptionSelected()">
                <div class="code-type-selector">
                    <h2>Select code type</h2>
                    <button
                        [ngClass]="{'button--black': this.codeType === 'single', 'button--blue': this.codeType !== 'single'}"
                        class="tt--button large"
                        (click)="selectCodeType('single')">Single code</button>
                    <button
                        [ngClass]="{'button--black': this.codeType === 'batch', 'button--blue': this.codeType !== 'batch'}"
                        class="tt--button large"
                        (click)="selectCodeType('batch')">Batch of codes</button>
                </div>
            </div>
        </div>
        <div class="code-step step-3"
            [ngClass]="{'disabled': selectedStep !== 3}">
            <h2 class="title-header" (click)="setStep(3)">
                <span class="number">4</span>
                Percentage Discount and number of codes (if batch)
            </h2>
            <div class="step-content info" *ngIf="!isSubscriptionSelected() || !isPublisherSelected()">
                <div class="info-wrapper info-left">
                    <i class="fa fa-info-circle"></i>
                    <p>
                        You didn't select publisher or subscription. Please follow all steps above.
                    </p>
                </div>
            </div>
            <div class="step-content code-form-wrapper" *ngIf="isSubscriptionSelected()">
                <div class="column split-line">
                    <form class="tt__form" [formGroup]="codesForm" novalidate>
                        <div class="element-holder" *ngIf="this.codeType === 'single'">
                            <h2>Discount code</h2>
                            <input type="text"
                                class="tt__input"
                                formControlName="code"
                                [ngClass]="{ 'input--error' : codesForm.get('code').invalid && codesForm.get('code').touched }"/>
                            <span class="form-error"
                                *ngIf="codesForm.get('code').hasError('required') && codesForm.get('code').touched">
                                Please enter your discount code
                            </span>
                        </div>
                        <div class="element-holder" *ngIf="this.codeType === 'batch'">
                            <h2>Number of codes</h2>
                            <input type="text"
                                class="tt__input"
                                inputmode="numeric"
                                formControlName="count"
                                [ngClass]="{ 'input--error' : codesForm.get('count').invalid && codesForm.get('count').touched }"/>
                            <span class="form-error"
                                *ngIf="codesForm.get('count').hasError('required') && codesForm.get('count').touched">
                                Please enter number of count to generate
                            </span>
                        </div>
                        <div class="element-holder">
                            <h2>Percent Discount</h2>
                            <div class="input-group input-percent">
                                <input
                                    type="number"
                                    pattern="[0-9]*"
                                    class="form-control tt__input"
                                    [ngClass]="{ 'input--error' : codesForm.get('percent').invalid && codesForm.get('percent').touched }"
                                    formControlName="percent"
                                    inputmode="numeric">
                                <div class="input-group-append">
                                  <span class="input-group-text">%</span>
                                </div>
                            </div>
                            <span class="form-error"
                                *ngIf="codesForm.get('percent').hasError('required') && codesForm.get('percent').touched">
                                Please enter your percentage of discount code
                            </span>
                        </div>
                        <div class="element-holder" *ngIf="this.codeType === 'single'">
                            <h2>Number of possible uses</h2>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                class="tt__input"
                                inputmode="numeric"
                                formControlName="limit"
                                [ngClass]="{ 'input--error' : codesForm.get('limit').invalid && codesForm.get('limit').touched }"/>
                            <span class="form-error"
                                *ngIf="codesForm.get('limit').hasError('required') && codesForm.get('limit').touched">
                                Please enter the number of how many times user can use this code.
                            </span>
                        </div>
                    </form>
                </div>
                <div class="column code-details">
                    <h2 class="text-center">Code Summary</h2>
                    <div class="generated-code-details"
                        *ngFor="let publisher of redemptionPublishers" [hidden]="!publisher.selected">
                        <h2 class="code-type">{{ codeType === 'single' ? 'Single code' : 'Batch of codes' }}</h2>
                        <div class="publisher">
                            <img [alt]="publisher.org"
                                    [title]="publisher.org"
                                    [src]="publisher.banner_image || publisher.profile_image"/>
                                    <a [href]="publisher.website"
                                        class="link"
                                        target="_blank"
                                    [title]="publisher.website">{{ publisher.org }}</a>
                        </div>
                        <div class="all-subs" *ngIf="publisher.checked">
                            All Publisher Subscriptions
                        </div>
                        <div class="subscription"
                            *ngFor="let s of publisher.subscriptions"
                            [hidden]="!s.selected">
                            <div class="sub-type">
                                <span class="price">{{ s.token_price | xsactCurrency:s.token_class:'cent' }}</span>
                                <span class="separator">per</span>
                                <span class="type">{{ displayPlan(s.plan_type) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-wrapper info-left" *ngIf="!noSubscriptionSetup">
            <i class="fa fa-info-circle"></i>
            <p>
                When you create a discount code, your account pays a percentage of
                the cost of a subscription.  When the purchaser of the subscription
                goes though the checkout process you must have sufficient funds in
                this account for application of the discount to succeed.
            </p>
        </div>
    </div>
    <div class="footer text-center">
        <button
            [disabled]="codesForm.invalid"
            (click)="addDiscountCode()"
            appLoadingState
            [loadingState]="addingCode"
            class="tt--button button--blue large">
            Add Discount Code
        </button>
    </div>
</div>

<div class="tt__panel-box">
    <div class="header">
        <h3>List of your codes</h3>
    </div>
    <div class="body">
        <div class="spinner" *ngIf="loadingCodes">
            <span class="fa fa-refresh fa-spin big"></span>
            <span class="label">Loading your codes...</span>
        </div>
        <div class="body-info" *ngIf="!codesList.length && !loadingCodes">
            You didn't create any discount codes yet.
        </div>
        <div class="table-holder" *ngIf="codesList.length && !loadingCodes">
            <table class="tt__table tr-hovered tr-pointer">
              <thead>
                <tr>
                    <th>Code</th>
                    <th>% of discount</th>
                    <th>Use limit</th>
                    <th>Used</th>
                    <th>Publisher</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th></th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let code of codesList">
                    <td>
                        <span class="code-label">{{ code.code }}</span>
                        <span class="code-info">{{ displayPlanLabel(code.sub_plan_id) }}</span>
                    </td>
                    <td>
                        <strong>{{ code.percent }} %</strong>
                    </td>
                    <td>{{ code.limit }}</td>
                    <td>{{ code.used }}</td>
                    <td>
                        <span *ngIf="!code.publisher" class="fa fa-refresh fa-spin"></span>
                        <a
                            *ngIf="code.publisher"
                            [href]="code.publisher.website" target="_blank">{{ code.publisher.org }}</a>
                    </td>
                    <td>
                        <span
                            [ngClass]="{'label--blue': code.active, 'label--red': !code.active}"
                            class="tt__label">{{ code.active ? 'ACTIVE' : 'DISABLED' }}</span>
                    </td>
                    <td>{{ code.created || code.updated | date: 'dd/MM/yyyy' }}</td>
                    <td class="col__actions actions">
                        <button type="button"
                                (click)="deleteCode(code)"
                                class="tt--button button--blue">Delete</button>
                    </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #questionModal>
    <section class="tt__modal remove-discount-code-modal" *ngIf="this.modalData">
      <div class="modal-header">
      <h3>Remove discount code</h3>
      <span class="refund-id-number">id: {{ this.modalData.id }}</span>
      </div>
      <div class="modal-body question-mark-bg">
        <i class="fa fa-question-circle"></i>
        <p class="question">
          Are you sure you want to remove this discount code?
        </p>
        <div class="code-details">
            <h2>{{ this.modalData.code }}</h2>
            <span class="discount">({{ this.modalData.percent }} % of discount)</span>
        </div>
      </div>
      <div class="modal-footer text-right">
        <button (click)="removeCode(); questionModalRef.hide()"
                class="tt--button button--black large">Yes</button>
        <button (click)="questionModalRef.hide()"
                class="tt--button button--blue large">Cancel</button>
      </div>

    </section>
  </ng-template>
