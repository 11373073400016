import { XctBaseType } from './xctBaseType';

type DocumentType = 'W9' | 'W_8BEN_E' | 'PHOTO_ID' | 'VAT';
type DocumentFormat = 'PDF' | 'JPG' | 'PNG';

export enum DocumentReviewState {
  // Not done yet
  NO = 0, // not reviewed
  OK = 1, // Approved
  REJECTED = 2, // Rejected as bad
}

export interface IDocument {
  doc_type: DocumentType;
  file_name: string;
  format: DocumentFormat;
  id: string;
  md5: string;
  owner_id: number;
  reviewed: number;
  removed: boolean;
  sha256: string;
  timestamp: number;
  uploader_id: number;
}

export class Document extends XctBaseType implements IDocument {
  doc_type: DocumentType = null; // what type of doc is this
  file_name = ''; // READ only, server sets this.
  format: DocumentFormat = null;
  id = ''; // ID in DB
  md5 = ''; // checksum
  owner_id: number = null;  // can be Group ID, or User ID, depending on who owns this doc.
  reviewed: number = null;
  removed: boolean = null; // marked for deletion
  sha256 = ''; // checksum
  timestamp: number = null; // timestamp of upload
  uploader_id: number = null;  // user ID of who uploaded it

  constructor(defaultsHash: any = {}) {
    super();
    const newObject = this.initializeFromDefaultsHash(defaultsHash);

    //  from kh
    newObject.reviewed = newObject.reviewed || 0;
  }

  // smk: "format" nomenclature confuses me. This makes the call unambiguous but
  // retains naming scheme used by API. TypeScript doesn't have simple aliases yet.
  getFileFormatLabel(): string {
    return this.format;
  }

  getFileName(): string {
    return this.file_name || '';
  }

  getId(): string {
    return this.id;
  }

  // getDocumentReviewState() {
  //   return this.reviewed;
  // }

  // This should probably go i18n?
  getDisplayLabel(): string {
    const documentType = this.doc_type;
    let label = 'undefined';

    if (documentType === 'W9') {
      label = 'W9 - Business name';
    } else if (documentType === 'W_8BEN_E') {
      label = 'Company Registration Doc';
    } else if (documentType === 'PHOTO_ID') {
      label = 'Photo ID';
    } else if (documentType === 'VAT') {
      label = 'VAT Number Proof';
    } else {
      console.error(`Failed to identify a label for doc_type '${documentType}'`);
    }

    return label;
  }

  hasRealData(): boolean {
    return this.id ? true : false;
  }

  iconClassForReviewedState(): string {
    const reviewedNumber = this.reviewed || 0;
    let iconClass = 'fa-question-circle';

    // This is a placebo Document indicating an *outstanding file that needs to be uploaded.
    if (!this.hasRealData()) {
      iconClass = null;
    } else if (reviewedNumber === 0) {
      iconClass = 'xct-is-tbd fa-hourglass';
    } else if (reviewedNumber === 1) {
      iconClass = 'xct-is-good fa-check-circle';
    } else if (reviewedNumber === 2) {
      // iconClass = 'fa-minus-circle';
      iconClass = 'xct-is-bad fa-exclamation-triangle';
    } else {
      console.error(`Failed to reviewed state for doc_type 'this.reviewed'=${this.reviewed}'`);
    }

    return iconClass;
  }



}
