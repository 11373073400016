import { User } from './../../services/user';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { CurUserService } from '../../services/cur-user.service';

import { XctFormValidationService } from '../../services/form_validation.service';

@Component({
  selector: 'app-form-elements-change-password',
  templateUrl: 'form-elements-change_password.component.html',
  styleUrls: ['../form-elements.scss', 'form-elements-change_password.component.scss'],
  providers: [XctFormValidationService],
})
export class FormElementsChangePasswordComponent implements OnInit {
  @Input() user: User;
  editPasswordForm: FormGroup;
  error: Record<string, boolean> = {};
  formSuccessText = '';
  loading = false;

  constructor(private formBuilder: FormBuilder, private curUserService: CurUserService) {}

  ngOnInit() {
    this.editPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPasswordConfirm: ['', [Validators.required, Validators.minLength(6)]],
      currentPassword: ['', [Validators.required]],
    });
  }

  savePassword() {
    if (this.editPasswordForm.invalid) {
      return;
    }
    // Clear any extant messages.
    this.clearFormMessages();
    this.loading = true;

    const hashForAPI = {
      new_password: this.editPasswordForm.get('newPassword').value,
      old_password: this.editPasswordForm.get('currentPassword').value,
    };

    // console.warn(`form-elements-change_password: hashForAPI`, hashForAPI);

    // This gets back a Promise.
    const responsePromise = this.curUserService.updateUserPassword(hashForAPI);

    // API returns error messages that need to show up on form,
    // e.g., "Current password as entered does not match what is on record."
    responsePromise
      .then((response: any) => {
        // this.utilitySvc.showToast('Password updated.');
        // console.warn(`${xctFormHelper.debugIdentifier()} 'updateUserPassword' response`, response);
        // this.xctFormSuccessText = 'Password successfully updated.';
        this.formSuccessText = response.message;
        this.editPasswordForm.reset();
        this.loading = false;
      })
      .catch((resp: any) => {
        this.error = {};
        this.error[resp.error.code] = true;
        this.loading = false;
      });

    // this.setupAllForms();
  }

  clearFormMessages(): void {
    this.error = {};
    this.formSuccessText = '';
  }
}
