import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { DefaultUrlSerializer, Router, UrlSerializer, UrlTree } from '@angular/router';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '@environments/environment';

import { TransactErrorHandler } from '@shared/services/transact_error_handle';
import { TransactBackendInterceptor } from '@shared/services/transact.backend';
import { XsactSharedModule } from '@shared/xsact_shared.module';

import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@Injectable()
export class StandardUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    const regex = /\(([^()]+)\)/g;
    if (url.match(regex)) {
      console.warn('Not allowed url (x).');

      const parsedUrl = url.replace(/["'\(\)]/g, '');
      return this.defaultUrlSerializer.parse(parsedUrl);
    }

    return this.defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    let url = this.defaultUrlSerializer.serialize(tree);
    const regex = /\(([^()]+)\)/g;

    if (url.match(regex)) {
      url = url.replace(/["'\(\)]/g, '');
    }

    return url;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    XsactSharedModule.forRoot(),
    XsactSharedModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: !environment.production,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useClass: TransactErrorHandler,
      deps: [HttpClient],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransactBackendInterceptor,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: StandardUrlSerializer,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
