import { FallbackImageDirective } from './fallback-image/fallback-image.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PasswordTriggerDirective } from './password-eye-trigger/password-trigger.directive';
import { CurrencyPriceDirective } from './currency-price/currency-price.directive';
import { TrimInputDirective } from './trim-input/trim-input.directive';
import { DigitOnlyDirective } from './digit-only/digit-only.directive';
import { ButtonLoadingStateDirective } from './button-loading-state/button-loading-state.directive';

@NgModule({
    declarations: [
        PasswordTriggerDirective,
        CurrencyPriceDirective,
        TrimInputDirective,
        DigitOnlyDirective,
        ButtonLoadingStateDirective,
        FallbackImageDirective,
    ],
    imports: [
        RouterModule,
        CommonModule
    ],
    exports: [
        PasswordTriggerDirective,
        CurrencyPriceDirective,
        TrimInputDirective,
        DigitOnlyDirective,
        ButtonLoadingStateDirective,
        FallbackImageDirective,
    ]
})

export class DirectivesModule {}
