import { Group } from './../services/group';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * This class represents the navigation bar component.
 */
@Component({
  selector: 'app-publisher-banner-image',
  templateUrl: 'publisher-banner-image.component.html',
  styleUrls: ['publisher-banner-image.component.scss'],
})
export class PublisherLogoComponent {
  @Input() publisher: Group;
}
