import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
// import * as _ from 'lodash';

@Component({
  selector: 'app-button-loading',
  templateUrl: 'form-elements-loading_button.component.html',
  styleUrls: ['form-elements-loading_button.component.scss'],
})
export class LoadingButtonComponent implements OnChanges {
  @Input() currentlyLoading: boolean;
  @Input() class: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Output() loadingButtonClick: EventEmitter<any> = new EventEmitter();

  currentlyDisabled: boolean;

  ngOnChanges(changes: any) {
    // Do nothing
    this.currentlyDisabled = this.disabled || this.currentlyLoading;
  }

  onButtonClicked(event: Event): void {
    event.stopPropagation();
    if (!this.currentlyDisabled) {
      this.loadingButtonClick.emit(event);
    }
  }
}
