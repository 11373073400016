import { AccountFunds, Currency } from '@shared/types/account_funds';
import { Address } from './../types/address';

import { Account } from './account';
import { Transaction } from './transaction';

// regular user or admin user role
export type UserRole = 'USER' | 'ADMIN' | 'UNKNOWN';

export class User extends Account {
  first_name: string;
  last_name: string;
  email1: string;
  groups: Array<number>;
  password: string;
  captcha: string; // used on signup only
  created: number;
  created_country: string;
  email_verified: number;
  email1_bounced: number;
  buyer: boolean;
  seller: boolean;
  staff: boolean;
  banned: boolean;
//  stripe_cust_id_TEST : string;
//  stripe_cust_id_PROD : string;
  role: UserRole;
  profile_img_url: string;
  totp_enabled: boolean;
  tokens_notify_below: number;
  tokens_auto_refill_value: number;
  tokens_auto_refill_charge: number;
  tokens_email_purchases: number;
  one_click: number[];
  referrer: string;
  referrer_transaction: Transaction;
  defaultCurrency: Currency;

  apikey: string;
  banner_image: string;
  closed: boolean;
  email1_domain: string;
  email1_verified: number;
  email_marketing: number;
  id: number;
  is_group: boolean;
  last_login: number;
  logout_timestamp: number;
  phone_number1: string;
  previous_emails: string[];
  profile_image: string;
  public_key: string;
  secret1: string;
  style: any;
  updated: number;
  verify_timestamp: number;
  website: string;

  constructor(options?: {[key: string]: any}) {
    super();
    this.address = new Address();
    this.id = 0;
    this.first_name = '';
    this.last_name = '';
    this.created = 0;
    this.email1 = '';
    this.secret1 = '';
    this.email_verified = 0;
    this.buyer = false;
    this.seller = false;
    this.staff = false;
    this.banned = false;
    this.role = 'USER';
    this.totp_enabled = false;
    this.tokens_notify_below = 0;
    this.tokens_auto_refill_value = 0;
    this.tokens_auto_refill_charge = 0;
    this.tokens_email_purchases = 0;
    this.one_click = [];
    this.defaultCurrency = Currency.PROD;

    if (typeof options !== 'undefined') {
      // eslint-disable-next-line
      for (const optionName in options) {
        Object.defineProperty(this, optionName, {
          value: options[optionName] as any,
          enumerable: true,
          writable: true
        });
      }
    }
  }

}


