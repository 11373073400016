import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ImageService {

  constructor(private http: HttpClient) {

  }

  getImage(imageUrl: string): Observable<Blob> {

    return this.http
        .get(imageUrl, {responseType: 'blob'} );
  }
}
