import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';

export interface ErrorMap {
  [key: string]: boolean;
}

// Centralize error messaging and prep for i18n.
//
// Based on:
//   https://coryrylan.com/blog/angular-2-form-builder-and-validation-management
//
// Here's some off-the-shelf validators (e.g., email, credit card) as foord for thought:
//   https://www.npmjs.com/package/ng2-validation

/* eslint-disable */
let xctRegexes = {
  // smk: This regex came from code this was based on (link above).
  // Visa, MasterCard, American Express, Diners Club, Discover, JCB
  creditCard: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,

  // This is regex that had been used in Xsact codebase prior.
  humanName: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,

  // This is regex that had been used in Xsact codebase prior.
  // emailRegex = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@' +
  //   '((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

  // RFC 2822 compliant regex
  emailAddress: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,

  // 8-15 chars, >=1 lower, >=1 upper, >=1 number, >=1 special - tougher rules maybe for later
  // pwRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,15}/;
  //
  // at least one number, one lowercase and one uppercase letter - this is what the server checks for
  // at least six characters
  passwordFormatSimple: /.{6,}/,
  passwordFormatOnly: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
};
/* eslint-enable */

const Stripe = (window as any).Stripe;

@Injectable()
export class XctFormValidationService {

  // static creditCardValidator(control: any) {
  //   // smk: This regex came from code this was based on (link above). Should definitely be conformed to proper credit card usage here.
  //   // Visa, MasterCard, American Express, Diners Club, Discover, JCB
  //   if (control.value.match(xctRegexes.creditCard)) {
  //     return null;
  //   } else {
  //     return { 'invalidCreditCard': true };
  //   }
  // }

  static humanName(control: FormControl): any {
    const value = control.value;
    const minimumLength = 3;

    if (value && value.match(xctRegexes.humanName)) {
      if (value.length < minimumLength) {
        return { minlength: { requiredLength: minimumLength } };
      } else {
        return null;
      }
    } else {
      return { invalidHumanName: true };
    }
  }

  static emailAddress(control: FormControl) {
    // console.warn(`control = ${control.value}`, control, control.value);

    if (control.value && control.value.match(xctRegexes.emailAddress)) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static accountValidator(value: string): boolean {
    return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(value.toUpperCase());
  }

  static achAccountValidator(value: string | any): boolean {
    return  value.length >= 4 && value.length <= 17;
  }

  static bankRoutingValidator(routingNumber: string, country: string): boolean {
    if (!routingNumber) {
      return false;
    }

    let routing = routingNumber.toString();
    while (routing.length < 9) {
      routing = '0' + routing;
    }

    const match = routing.match('^\\d{9}$');
    if (!match) {
      return false;
    }

    const firstTwo = parseInt(routingNumber.substring(0, 2), 10);
    const firstTwoValid =  (0 <= firstTwo && firstTwo <= 12)
                        || (21 <= firstTwo && firstTwo <= 32)
                        || (61 <= firstTwo && firstTwo <= 72)
                        || firstTwo === 80;
    if (!firstTwoValid) {
      return false;
    }

    const weights = [3, 7 , 1];
    let sum = 0;
    for (let i = 0 ; i < 8; i++) {
      sum += parseInt(routingNumber[i], 10) * weights[i % 3];
    }

    return (10 - (sum % 10)) % 10 === parseInt(routing[8], 10);
  }

  static ibanAccountValidator(ibanAccount: string): boolean {
    /*
    Structure of International Bank Account Number (“IBAN”)

    The IBAN consists of up to 34 alphanumeric characters which formatted as below

    CCKKBBBBBBBBBBBB...

    The first two 2 characters ("CC") specify the country code as of ISO 3166-1 alpha-2. Only letters.
    The next 2 characters ("KK") specify the check digits for a sanity check. Only digits.
    The remaining characters up to 30 characters ("BBBBBBBBBBBB...") specify the Basic Bank Account Number (“BBAN”).
    The format is decided by the national central bank or designated payment authority of each country.
    Both letters & digits allowed.

    */
    const isValidIBAN = (iban: string) => { // checks the checksum
      let acct: string = iban.replace(/^(.{4})(.*)$/, '$2$1'); // Move the first 4 chars from left to the right
      // Convert A-Z to 10-35
      acct = acct.replace(/[A-Z]/g,
        (e) => (e.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString()
      );
      let theSum = 0;
      let ei = 1; // First exponent
      for (let i = acct.length - 1; i >= 0; i--) {
        const nextChar = acct.charAt(i);
        const charInt = parseInt(nextChar, 10);
        theSum += ei * charInt; // multiply the digit by it's exponent
        ei = (ei * 10) % 97; // compute next base 10 exponent  in modulus 97
      }
      return theSum % 97 === 1;
    };

    // Every country has a slightly different IBAN pattern - Not every country uses IBAN
    // to find the country IBAN patterns go to http://ht5ifv.serprest.pt/extensions/tools/IBAN/
    const ibanRegex = new RegExp ([
      '^AT\\d{18}$|^BE\\d{14}$|^DK\\d{16}$|^FO\\d{16}$|^GL\\d{16}$|',
      '^FI\\d{16}$|^FR\\d{12}[0-9A-Z]{11}\\d{2}$|^DE\\d{20}$|',
      '^IE\\d{2}[A-Z]{4}\\d{14}$|^IT\\d{2}[A-Z]\\d{10}[0-9A-Z]{12}$|',
      '^LU\\d{5}[0-9A-Z]{13}$|^NL\\d{2}[A-Z]{4}\\d{10}$|^NO\\d{13}$|',
      '^PT\\d{23}$|^ES\\d{22}$|^SE\\d{22}$|^CH\\d{7}[0-9A-Z]{12}$|',
      '^GB\\d{2}[A-Z]{4}\\d{14}$'
    ].join(''));
    ibanAccount = ibanAccount.replace(/[-. ]/g, '');
    const patternTest = ibanRegex.test(ibanAccount);
    const checksumTest = isValidIBAN(ibanAccount);
    return patternTest && checksumTest;
  }

  static swiftCodeValidator(ctl: FormControl): any {
    /*
    The Swift code consists of 8 or 11 characters. When 8-digits code is given, it refers to the primary office.
    The code formatted as below:

    AAAA BB CC DDD

    First 4 characters - bank code (only letters)
    Next 2 characters - ISO 3166-1 alpha-2 country code (only letters)
    Next 2 characters - location code (letters and digits) (passive participant will have "1" in the second character)
    Last 3 characters - branch code, optional ('XXX' for primary office) (letters and digits)

    /[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/
    */
    if (ctl.value === '') { return null; }
    const swiftRegexp = /[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/;
    const theError: any = {
      invalidSwiftCode: {
        valid: false
      }
    };
    return swiftRegexp.test(ctl.value) ? null : theError;
  }

  static bankAccountValidator(ctl: FormControl): any {
    let type: string;
    let country: string;
    if (ctl.value === '') { return null; }
    if (ctl.parent) {
      type = (( ctl.parent as FormGroup).controls.type).value;
      country = (( ctl.parent as FormGroup).controls.country).value;
      country = country || 'US';
    } else {
      country = 'US';
      type = 'ACH';
    }
    let result = false;
    let theError: any = {
      invalidAccountNumber: {
        valid: false
      }
    };

    switch (type) {
      case 'IBAN' :
        result = XctFormValidationService.ibanAccountValidator(ctl.value);
        theError = {
          invalidIBANAccount: {
            valid: false
          }
        };
        break;
      case 'ACH' :
        result = XctFormValidationService.achAccountValidator(ctl.value);
        break;
      case 'SWIFT' :
        result = XctFormValidationService.accountValidator(ctl.value);
        break;
      default :
    }
    return result ? null : theError;
  }

  static routingNumberValidator(ctl: FormControl): any {
    if (ctl.value === '') { return null; }
    let country: string;
    if (ctl.parent) {
      country = (( ctl.parent as FormGroup).controls.country).value;
      country = country || 'US';
    } else {
      country = 'US';
    }
    const theError: any = {
      invalidRoutingNumber: {
        valid: false
      }
    };
    return XctFormValidationService.bankRoutingValidator(ctl.value, country) ? null : theError;
  }


  static passwordFormatOnly(control: FormControl): any {
    const value = control.value;
    const minimumLength = 6; // Yes, this is in two places right now

    if (value) {
      if (value.length < minimumLength) {
        return { minlength: { requiredLength: minimumLength } };
      }
      return null;
      // else if (value.match(xctRegexes.passwordFormatOnly)) {
      //   return null;
      // }
    }

    // return { 'invalidPassword': true };
  }

  /* eslint-disable @typescript-eslint/ban-types, prefer-arrow/prefer-arrow-functions */
  static fieldsMatch(validateFieldName: string, targetFieldName: string): Function {
    return function(group: FormGroup): any {
      const inputToMatch = group.controls[targetFieldName];
      const confirmationInput = group.controls[validateFieldName];
      let errorObject: any = confirmationInput.errors || {};

      if (inputToMatch.value !== confirmationInput.value) {
        if (errorObject) {
          errorObject.notEquivalent = true;
        } else {
          errorObject = {};
        }
      } else {
        if (errorObject) {
          delete errorObject.notEquivalent;
          if (_.isEmpty(errorObject)) {
            errorObject = null;
          }
        }
      }
      return confirmationInput.setErrors(errorObject);
    };
  }

  // Map a propertyName (key in errors hash) to a verbose error message for user.
  // This is where i18n handling can go.
  public getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      // Angular-defined validation names.
      required: 'This field is required.',
      minlength: `Please enter at least ${validatorValue.requiredLength} characters.`,

      // Xsact's custom validation names.
      // 'invalidCreditCard': 'Invalid credit card number.',
      invalidEmailAddress: 'Invalid email address.',
      invalidPhoneNumber : 'Invalid phone number',
      invalidHumanName: 'Please enter a valid name.',
      invalidPassword: 'Passwords require at least one uppercase and one lowercase letter, and one number.',
      invalidTestTokensAmount: `Test token balance must be at least $${validatorValue.minimumAmount}.`,
      validateEqual: 'Your new passwords are not identical.',
    };

    let finalErrorText = (config as any)[validatorName];

    if (!finalErrorText) {
      console.error(`No error text found for 'validatorName' = ${validatorName}`, validatorValue);
      finalErrorText = '(error encountered)';
    }

    return finalErrorText;
  }

}
