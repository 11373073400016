import { Component, Input } from '@angular/core';

import { Transaction } from '../../services/transaction';

const defaultOptions = {
  displayFormat: 'default',
  isDebug: false,
};

@Component({
  selector: 'app-elements-transaction-single',
  templateUrl: 'elements-transaction-single.component.html',
  styleUrls: ['../elements.scss', 'elements-transaction-single.component.scss'],
})
export class ElementsTransactionSingleComponent {
  @Input() transaction: Transaction;
  @Input() options: any = {};

  // 1-based counter of what number the current item represents in the parent list (optional).
  @Input() counter = 0;

  // Total number of items in the list. This is really a workaround for failure in CSS to catch the
  // "first-of-type" or "first-of-child". Must be a better way.
  @Input() totalCount = 0;

  getOption(optionToGet: string): string {
    const localDefault = (defaultOptions as any)[optionToGet];
    const optionsHashToUse = this.options;
    const passedOption = optionsHashToUse[optionToGet];
    let returnValue = '';

    if (typeof localDefault === 'undefined') {
      console.error(
        `Requested option ${optionToGet} for 'elements-transaction-list' is INVALID.`,
        defaultOptions
      );
    } else if (passedOption) {
      returnValue = passedOption;
    } else {
      returnValue = localDefault;
    }

    return returnValue;
  }

  // Unclear if this is ALWAYS current publisher? Should really be a call on Transaction to access the related Publisher?
  getImageUrl(): string {
    let url = '';
    const options = this.options;
    const currentPublisherService = options.currentPublisherService;

    // console.info('currentPublisherService', currentPublisherService);

    if (options.imageUrl) {
      url = options.imageUrl;
    } else if (currentPublisherService) {
      // This is ugly workaround. If we're dealing with a transaction, that transaction ought know what the owner publisher is,
      // and from that it ought know what that publisher's logo url is. But for now the use cases seem to revolve only the
      // CURRENT publisher, so the workaround is pass 'currentPublisherService' so that logic of figuring out proper logo url
      // lives here and not in caller.
      url = currentPublisherService.getUrl_profileImage50x50();
      // url = this.currentPublisherService.getUrl_profileImage();
      // url = this.currentPublisherService.getUrl_bannerImage();
    }

    return url;
  }

  getCreditsCountText(): string {
    const creditsCount = this.transaction.getCredits();
    if (creditsCount) {
      if (creditsCount > 1) {
        return `${creditsCount} cents`;
      } else {
        return `1 cent`;
      }
    } else {
      return `Zero cents`;
    }
  }

  isThisTheFirstItemInList(): boolean {
    return this.counter === 1;
  }

  isThisTheLastItemInList(): boolean {
    return this.counter === this.totalCount;
  }
}
