import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appEye]',
})
export class PasswordTriggerDirective implements OnInit {
  element: any;
  eyeTriggered = false;
  elementId: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: any) {
    if (
      (targetElement.classList.contains('fa-eye') &&
        targetElement.parentNode &&
        targetElement.parentNode.getAttribute('id') === this.elementId) ||
      (targetElement.classList.contains('fa-eye-slash') &&
        targetElement.parentNode &&
        targetElement.parentNode.getAttribute('id') === this.elementId)
    ) {
      this.changeInputType();
    }
  }

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.createEyedPassword();
  }

  createEyedPassword() {
    const inputWrapper = this.renderer.createElement('div');
    this.elementId = `eye-${Math.floor(Math.random() * 100000)}`;

    this.renderer.insertBefore(this.element.parentNode, inputWrapper, this.element);
    this.renderer.addClass(inputWrapper, 'password-eye');
    this.renderer.appendChild(inputWrapper, this.element);
    this.renderer.setAttribute(inputWrapper, 'id', this.elementId);

    const eyeIconItem = this.renderer.createElement('i');

    this.renderer.setAttribute(eyeIconItem, 'aria-hidden', 'true');
    this.renderer.setAttribute(eyeIconItem, 'title', 'Show password');
    this.renderer.addClass(eyeIconItem, 'fa');
    this.renderer.addClass(eyeIconItem, 'fa-eye');
    this.renderer.appendChild(inputWrapper, eyeIconItem);
  }

  changeInputType() {
    this.eyeTriggered = !this.eyeTriggered;

    const localEyeIco = this.element.parentNode.querySelector('.fa');

    if (this.eyeTriggered) {
      this.renderer.removeClass(localEyeIco, 'fa-eye');
      this.renderer.addClass(localEyeIco, 'fa-eye-slash');
      this.renderer.setAttribute(this.element, 'type', 'text');
      this.renderer.setAttribute(localEyeIco, 'title', 'Hide password');
    } else {
      this.renderer.removeClass(localEyeIco, 'fa-eye-slash');
      this.renderer.addClass(localEyeIco, 'fa-eye');
      this.renderer.setAttribute(this.element, 'type', 'password');
      this.renderer.setAttribute(localEyeIco, 'title', 'Show password');
    }
  }
}
