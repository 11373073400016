<section class="file-upload-wrapper">
    <button
        class="tt--button button--blue large"
        appLoadingState
        [loadingOnClick]="false"
        [loadingState]="processingState"
        (click)="selectUploadFile()">
        {{ label }}
        <!-- images for profile and docuements.  PDF for documents-->
        <input #inputFile type="file" accept="image/*,application/pdf" (change)="uploadFileOnChange($event)"/>
    </button>
    <div class="message"
        [ngClass]="{'success': messageInfo.type === 'success', 'error': messageInfo.type === 'error'}"
        *ngIf="showMessages && messageInfo && (messageInfo.message && messageInfo.type)">
        <i class="fa fa-info-circle"></i>
        <span class="info">{{ messageInfo.message }}</span>
    </div>
    <p class="description" *ngIf="showDescription">
        {{ description }}
    </p>
</section>