<div class="tt__m-t-10" *ngFor="let alert of alerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout" [dismissible]="true">
    <span [innerHTML]="alert.msg"></span>
  </alert>
</div>

<form [formGroup]="editUserForm" (ngSubmit)="saveUser()" class="user-form" novalidate>
  <div class="form-group">
    <label class="control-label" i18n>First Name</label>
    <input type="text" formControlName="first_name" class="form-control" placeholder="Pat">
    <app-validated-input class="tooltip-style" [errors]="editUserForm.get('first_name').errors"
                     [hidden]="editUserForm.get('first_name').valid || !editUserForm.get('first_name').touched">
      <app-validation-message i18n displayOn="required">First name is required.</app-validation-message>
      <app-validation-message displayOn="minlength" i18n>Please enter at least 3 characters.</app-validation-message>
    </app-validated-input>
  </div>

  <div class="form-group">
    <label class="control-label" i18n>Last Name</label>
    <input type="text" formControlName="last_name" class="form-control" placeholder="Smith">
    <app-validated-input class="tooltip-style" [errors]="editUserForm.get('last_name').errors"
                     [hidden]="editUserForm.get('last_name').valid || !editUserForm.get('last_name').touched">
      <app-validation-message i18n displayOn="required">Last name is required.</app-validation-message>
      <app-validation-message displayOn="minlength" i18n>Please enter at least 3 characters.</app-validation-message>
    </app-validated-input>
  </div>

  <div
    class="form-group"
    (click)="checkEmailStatus()">
    <label class="control-label" i18n>Email Address</label>
    <input
      type="text"
      formControlName="email1"
      class="form-control" 
      autocomplete="email"
      placeholder="pat_smith@gmail.com"
      autocorrect="off"
      autocapitalize="off">
    <app-validated-input class="tooltip-style" [errors]="editUserForm.get('email1').errors"
                     [hidden]="editUserForm.get('email1').valid || !editUserForm.get('email1').touched">
      <app-validation-message i18n displayOn="required">Email is required.</app-validation-message>
      <app-validation-message i18n displayOn="invalidEmailAddress">Email is not valid.</app-validation-message>
    </app-validated-input>
  </div>

  <div class="form-group">
    <button 
      class="tt--button button--blue large tt__m-0"
      appLoadingState
      [loadingState]="loading"
      [disabled]="loading">
        Save name &amp; email
    </button>
  </div>
</form>
