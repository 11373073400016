import { XctTypesUtilityShim } from './xctTypesUtilityShim';

const xctTypesUtilityShim = new XctTypesUtilityShim();

export class XctBaseType {
  extractFieldsHashFromForm(formGroup: any) {
    return xctTypesUtilityShim.extractFieldsHashFromForm(this, formGroup);
  }

  flattenAllFieldsIntoHash(propertyName: string, baseHash: any) {
    return xctTypesUtilityShim.flattenAllFieldsIntoHash(this, propertyName, baseHash);
  }

  // Kept this out of constructor so that subclass determines whether to invoke it or not.
  protected initializeFromDefaultsHash(defaultsHash: any = {}) {
    const newObject: any = this;

    if (Object.keys(defaultsHash).length) {
      // console.info('newObject/defaultsHash', newObject, defaultsHash);
      Object.keys(defaultsHash)
        // Filter to include *only* keys passed in defaults hash that actually EXIST in the object itself.
        .filter(defaultKey =>
          // Tricky to get at *uninitialized* properties. Class MUST initialize any fields if they are to be
          // identified in this bit here or via 'hasOwnProperty'.
          // console.info('defaultKey', defaultKey, defaultKey in newObject, newObject.hasOwnProperty(defaultKey));
           (defaultKey in newObject)
        )
        .map(defaultKey => {
          const defaultValue = defaultsHash[defaultKey];

          // Let subclass handle class-specific fields introspection.
          // if (defaultKey === 'address') {
          //   defaultValue = new Address(defaultValue);
          // }
          newObject[defaultKey] = defaultValue;
        });
    }

    return newObject;
  }

}
