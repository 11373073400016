export enum TokenClass {
  PROD = 'PROD',  //USD
  TEST = 'TEST',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  CAD = 'CAD',
}

// ToDo use this in FundsLogController.logSingle
export class TokenClassKeyValue {
  [token_class: string]: number;
}
