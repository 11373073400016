<section class="discount-code">
    <form novalidate [formGroup]="codeForm">
        <input 
            type="text"
            class="tt__input"
            formControlName="code"
            placeholder="Discount Code"
            [ngClass]="{ 'input--error' : codeForm.get('code').invalid && codeForm.get('code').touched }"/>
        <button
            class="tt--button button--blue large"
            appLoadingState
            [loadingState]="applyingCode"
            (click)="applyCode()">Apply</button>
    </form>
    <div class="info"
        [ngClass]="{'success': alert.type === 'success', 'error': alert.type === 'error'}">
        <i class="fa fa-info-circle"></i>
        <p>
            {{ alert.msg || 'You can set your discount code here.' }}
        </p>
    </div>
</section>