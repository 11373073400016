<section
    class="credit-card-wrapper">
    <form #creditCardElement
        novalidate
        [formGroup]="creditCardForm"
        id="payment_form">
        <div id="paymentRequest">
          <!--Stripe paymentRequestButton Element inserted here-->
        </div>
        <div class="row">
            <div class="field">
                <label for="cardholder-name">Name</label>
                <input
                    id="cardholder-name"
                    [ngClass]="{ 'is-invalid' : creditCardForm.get('name').invalid && creditCardForm.get('name').touched }"
                    class="input"
                    (focus)="onInputEvent($event)"
                    (keyup)="onInputEvent($event)"
                    (blur)="onInputEvent($event)"
                    type="text"
                    placeholder="Jane Doe"
                    formControlName="name"
                    required=""
                    autocomplete="name">
            </div>
        </div>
        <div data-locale-reversible>
        <div class="row">
            <div class="field">
                <label for="cardholder-address">Address</label>
                <input
                    id="cardholder-address"
                    class="input empty"
                    [ngClass]="{ 'is-invalid' : creditCardForm.get('address').invalid && creditCardForm.get('address').touched }"
                    (focus)="onInputEvent($event)"
                    (keyup)="onInputEvent($event)"
                    (blur)="onInputEvent($event)"
                    type="text"
                    placeholder="185 Berry St"
                    formControlName="address"
                    required=""
                    autocomplete="address-line1">
            </div>
            <div class="field">
                <label for="cardholder-country">Country</label>
                <select name="address_country"
                        class="input empty"
                        formControlName="country"
                        (change)="changeCountry()"
                        (focus)="onInputEvent($event)"
                        (click)="onInputEvent($event)"
                        [ngClass]="{ 'is-invalid' : creditCardForm.get('country').invalid && creditCardForm.get('country').touched }"
                        autocomplete="address-country">
                    <option value="" disabled i18n>Country</option>
                    <option id="cardholder-country"
                            *ngFor="let country of countries"
                            [selected]="country.selected"
                            [ngValue]="country.code" i18n9>
                        {{ country.name }}
                    </option>
                </select>
                <div class="arrow-toggle"></div>
            </div>
        </div>
        <div class="row" data-locale-reversible>
            <div class="field">
                <label for="cardholder-city">City</label>
                <input
                    id="cardholder-city"
                    class="input empty"
                    [ngClass]="{ 'is-invalid' : creditCardForm.get('city').invalid && creditCardForm.get('city').touched }"
                    (focus)="onInputEvent($event)"
                    (keyup)="onInputEvent($event)"
                    (blur)="onInputEvent($event)"
                    type="text"
                    formControlName="city"
                    placeholder="San Francisco"
                    required=""
                    autocomplete="address-level2">
            </div>
            <div class="field" *ngIf="creditCardForm.get('country').value !== 'US'">
                <label for="cardholder-region">Region</label>
                <input
                    id="cardholder-region"
                    class="input empty"
                    [ngClass]="{ 'is-invalid' : creditCardForm.get('state').invalid && creditCardForm.get('state').touched }"
                    (focus)="onInputEvent($event)"
                    (keyup)="onInputEvent($event)"
                    (blur)="onInputEvent($event)"
                    type="text"
                    formControlName="state"
                    placeholder="Your Region Name"
                    required=""
                    autocomplete="address-level2">
            </div>
            <div class="field" *ngIf="creditCardForm.get('country').value === 'US'">
                <label for="cardholder-state">State</label>
                <select id="cardholder-state"
                        class="input empty"
                        [ngClass]="{ 'is-invalid' : creditCardForm.get('state').invalid && creditCardForm.get('state').touched }"
                        (change)="changeState()"
                        (focus)="onInputEvent($event)"
                        (click)="onInputEvent($event)"
                        name="address-level1"
                        formControlName="state"
                        autocomplete="address-level1">
                    <option value="" selected disabled i18n>State</option>
                    <option *ngFor="let state of stateService.states" [ngValue]="state.abbreviation">
                    {{ state.name }}
                    </option>
                </select>
                <div class="arrow-toggle"></div>
            </div>
            <div class="field">
                <label for="cardholder-zip">ZIP</label>
                <input
                    id="cardholder-zip"
                    class="input empty"
                    type="text"
                    [ngClass]="{ 'is-invalid' : creditCardForm.get('zip').invalid && creditCardForm.get('zip').touched }"
                    (focus)="onInputEvent($event)"
                    (keyup)="onInputEvent($event)"
                    (blur)="onInputEvent($event)"
                    placeholder="94107"
                    formControlName="zip"
                    required=""
                    autocomplete="postal-code">
            </div>
        </div>
        </div>
        <div class="row">
        <div class="field">
            <label for="card-element">Card</label>
            <div id="card-element" class="input"></div>
        </div>
        </div>
        <!-- <button class="tt--button button--blue large" type="submit">Pay $25</button> -->
        <div class="credit-card-message success">
            <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <circle class="border" cx="42" cy="42" r="40" stroke-linecap="round" stroke-width="4" stroke="#000" fill="none"></circle>
                <path class="checkmark" stroke-linecap="round" stroke-linejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" stroke-width="4" stroke="#000" fill="none"></path>
            </svg>
            <span class="message">Your credit card is valid!</span>
        </div>
        <div class="credit-card-message error" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                <path class="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"></path>
                <path class="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"></path>
            </svg>
            <span class="message">Error</span>
        </div>
      </form>
</section>
