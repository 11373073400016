// import * as Debug_ from 'debug';
// import Debug = Debug_;

// const debug = Debug('shared:StripeService');

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Currency } from '@shared/types';
import { BehaviorSubject } from 'rxjs';
import { InvisibleCaptchaService } from './invisible-captcha.service';

const baseUrl = '/api/stripe/';

export class StripeCard {
  id: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  brand: string;
  customer: string;
  brandAsset: string;
  expiration: string;
  expired?: boolean;
  default: boolean;
}

export class CreditCard {
  number = ''; // eslint-disable-line
  cvc = '';
  exp_month = 0;
  exp_year = 0;
  exp_date = '';
  name = '';
  address_line1 = '';
  address_line2 = '';
  address_city = '';
  address_state = '';
  address_zip = '';
  address_country = '';
  make_primary = false;
  last4 = '';
}

export class StripeErrors {
  public invalid_number: boolean;
  public invalid_expiry_month: boolean;
  public invalid_expiry_year: boolean;
  public invalid_expiry: boolean;
  public invalid_cvc: boolean;
  public invalid_type: boolean;
  public incorrect_number: boolean;
  public expired_card: boolean;
  public incorrect_cvc: boolean;
  public incorrect_zip: boolean;
  public card_declined: boolean;
  public missing: boolean;
  public processing_error: boolean;

  constructor() {
    this.invalid_number = false;
    this.invalid_expiry_month = false;
    this.invalid_expiry_year = false;
    this.invalid_expiry = false;
    this.invalid_cvc = false;
    this.invalid_type = false;
    this.incorrect_number = false;
    this.expired_card = false;
    this.incorrect_cvc = false;
    this.incorrect_zip = false;
    this.card_declined = false;
    this.missing = false;
    this.processing_error = false;
  }
}

export class StripeResponse {
  message = '';
  error: any;
  card: StripeCard;
  stripe_errors: StripeErrors;
  stripe_response: any;
}

export const ExpDateRegEx = '^(0[1-9]|1[0-2]|[1-9])[/ -]?([0-9]{4}|[0-9]{2})$';

// These are unused because they can't be translated. Here just for reference
export const StripeMessages = {
  invalid_number: 'The card number is not a valid credit card number.',
  invalid_expiry_month: "The card's expiration month is invalid.",
  invalid_expiry_year: "The card's expiration year is invalid.",
  invalid_expiry: 'The expiration is not a valid date',
  invalid_cvc: "The card's security code is invalid.",
  invalid_type: 'Unknown card type',
  incorrect_number: 'The card number is incorrect.',
  expired_card: 'The card has expired.',
  incorrect_cvc: "The card's security code is incorrect.",
  incorrect_zip: "The card's zip code failed validation.",
  card_declined: 'The card was declined.',
  missing: 'There is no card on a customer that is being charged.',
  processing_error: 'An error occurred while processing the card.',
};

export const CcAssetFiles = {
  'American Express': 'amex_cc.png',
  'MasterCard': 'masterc_cc.png',
  'Visa': 'visa_cc.png',
  'Discover': 'discover_cc.png',
  'Diners Club': 'dinersc_cc.png',
  'JCB': 'jcb_cc.png',
};

@Injectable()
export class StripeService {
  validateCreditCardSub = new BehaviorSubject<any>(null);
  validateCreditCardStatus = this.validateCreditCardSub.asObservable();

  cardFieldsNotToSend: string[] = ['exp_date', 'make_primary', 'last4'];
  stripe: any;
  stripeKeySet = false;

  constructor(private http: HttpClient, private captchaService: InvisibleCaptchaService) {}

  respOK() {
    // debug('respOK');
  }

  // Stripe must be initialized after stripe.js, in index.html has had time tim load
  // So only initialize stripe just in time before we need it.
  initStripeKey() {
    // only do this once
    if (this.stripeKeySet) {
      return;
    }

    if (!environment.stripePubKey || environment.stripePubKey.length < 3) {
      console.log('Stripe pubKey config not set!');
    }

    if ((window as any).Stripe) {
      this.stripe = (window as any).Stripe(environment.stripePubKey);
      this.stripeKeySet = true;
    }
  }

  getStripeToken() {
    return environment.stripePubKey;
  }

  getCards(): Promise<any> {
    return this.http.get(baseUrl + 'cards').toPromise();
  }

  chargeExistingCard(card_id: string, amount: number, recipientId: number): Promise<any> {
    const body = {
      card_id,
      amount,
      recipientId,
    };

    return this.http
      .post(baseUrl + 'card', body)
      .toPromise()
      .then(
        (result) => result,
        (result) => Promise.reject(result.error)
      );
  }

  postCharge(token: string, amount: number, saveCard: boolean, recipientId: number): Promise<any> {
    const data = {
      stripeToken: token,
      amount,
      remember_card: saveCard ? true : false,
      recipientId,
    };

    // debug('sending data', data);
    return this.http.post(baseUrl + 'charge', data).toPromise();
  }

  removeCard(cardToRemove: StripeCard): Promise<any> {
    // debug('removeCard');
    return this.http.delete(baseUrl + 'card/' + cardToRemove.id).toPromise();
  }

  addCardToServer(token: any, make_primary: boolean): Promise<any> {
    return this.http
      .put(baseUrl + 'card/' + encodeURIComponent(token), { primary: make_primary })
      .toPromise();
  }

  setPrimary(cardIdToMakePrimary: string): Promise<any> {
    const headers = new HttpHeaders();
    return this.http
      .post(
        baseUrl + 'card/' + cardIdToMakePrimary + '/default',
        {},
        {
          headers,
        }
      )
      .toPromise();
  }

  getPaymentIntent(
    cents: number | string,
    recipientId: number,
    saveCard: boolean,
    currency?: Currency | string,
    cardId?: number | string,
    captchaToken?: string
  ): Promise<any> {
    const params = new HttpParams()
      .set('save_card', saveCard.toString())
      .set('payment_method', cardId ? cardId.toString() : '')
      .set('recipientId', recipientId ? recipientId.toString() : '');

    const currencyCode =
      currency === Currency.PROD ? 'usd' : currency ? currency.toLowerCase() : 'usd';

    let captchaHeaders: Promise<HttpHeaders | undefined>;
    if (captchaToken) {
      captchaHeaders = Promise.resolve(new HttpHeaders({ greCaptcha: captchaToken }));
    } else {
      captchaHeaders = this.captchaService
        .tryExecute()
        .then((result) => (result ? new HttpHeaders({ 'gre-invisible': result }) : undefined));
    }

    return captchaHeaders.then((headers) =>
      this.http
        .get(`${baseUrl}payment_intent/${cents}/${currencyCode}`, { params, headers })
        .toPromise()
    );
  }

  getPaymentIntentVerify(paymentIntent: any): Promise<any> {
    return this.http.post(`${baseUrl}payment_intent/verify`, { id: paymentIntent }).toPromise();
  }

  handleServerError(err: any, stripeResponse: StripeResponse) {
    //    debug('stripe.handleServerError', err);

    const errorResponse = err;
    stripeResponse.error = errorResponse.error || errorResponse;
    stripeResponse.message = stripeResponse.error.message || '';
    // Object.defineProperty(stripeResponse.stripe_errors,errorResponse.error.code, { value: true });
  }

  handleStripeResponse(stripeResp: any, stripeResponse: StripeResponse) {
    //    debug('stripeResp', stripeResp);
    if (stripeResp.error) {
      // we have an error
      stripeResponse.error = stripeResp.error;
      Object.defineProperty(stripeResponse.stripe_errors, stripeResp.error.code, { value: true });
      stripeResponse.message = stripeResp.error.message;
    } else {
      // normal card returned
      stripeResponse.card = stripeResp;
    }
    // debug('stripeResponse', stripeResponse);
  }

  setCardValidationStatus(status: string | boolean) {
    // console.log('setCardValidationStatus', status);
    this.validateCreditCardSub.next(status);
  }

  validateCreditCardForm(): Promise<boolean | string> {
    this.validateCreditCardSub.next('validate');

    return new Promise((resolve) => {
      this.validateCreditCardStatus.subscribe((result) => {
        // console.log('status in promise', result);
        if (result === 'valid' || result === 'invalid') {
          resolve(result);
        }
      });
    });
  }
}
