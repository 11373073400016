import {
  Component,
  Input,
  ContentChildren,
  QueryList,
  AfterContentInit,
  OnChanges,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ValidationMessageComponent } from './validation-message.component';
// import * as _ from 'lodash';

export interface ErrorMap {
  [key: string]: boolean;
}

@Component({
  selector: 'app-validated-input',
  template: `<ng-content></ng-content>`,
  styleUrls: ['validated-input.component.scss'],
})
export class ValidatedInputComponent implements AfterContentInit, OnChanges {
  @Input() errors: ErrorMap;
  @ContentChildren(ValidationMessageComponent) messages: QueryList<ValidationMessageComponent>;

  private messagesList: ValidationMessageComponent[];

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterContentInit() {
    this.messagesList = this.messages.toArray();
    this.updateMessages();
  }

  ngOnChanges(changes: any) {
    this.updateMessages();
  }

  private updateMessages() {
    this.elementRef.nativeElement.classList.remove('has-error');
    if (this.messagesList) {
      /* eslint-disable @typescript-eslint/prefer-for-of */
      for (let i = 0; i < this.messagesList.length; i++) {
        this.messagesList[i].display = false;
      }
      if (this.errors) {
        let hasError = false;
        for (let i = 0; i < this.messagesList.length; i++) {
          const message = this.messagesList[i];

          if (message.displayOn && this.errors[message.displayOn]) {
            message.display = true;
            hasError = true;
            break;
          } else if (
            message.displayOn === '*' &&
            Object.keys(this.errors).length !== 0 &&
            typeof this.errors === 'object'
          ) {
            message.display = true;
            hasError = true;
            break;
          }
        }

        if (hasError) {
          this.renderer.addClass(this.elementRef.nativeElement, 'has-error');
        }
      }
    }
  }
}
