import { TokenClass } from './token_class';

export enum Currency {
  TEST = 'TEST',
  PROD = 'PROD',
  EUR = 'EUR',
  GBP = 'GBP',
  CAD = 'CAD',
  JPY = 'JPY',
}

type IAccountFunds = {
  [C in Currency]: number;
};

export class AccountFunds implements IAccountFunds {
  id: number;
  shard: number;
  TEST: number; // test currency
  PROD: number; // USD  is main production currency
  EUR: number; // Euro
  GBP: number;
  JPY: number;
  CAD: number;
  updated: number;
  default_currency: TokenClass;
  default_total: number;
}

