import { Account } from './account';
import { Address } from '../types/address';

export interface PublisherStyleConfig {
  bannerBgColor?: string;
  sidebarTitle?: string;
  sidebarText?: string;
  // We can add the primary color in here as well
}

export class GroupMailingList {
  name: string;
  id: string;
  tag: string;
}

export class GroupMailchimpSettings {
  api_key: string;
  subscriber_lists: {
    [key: string]: GroupMailingList;
  };
  subscription_period_tags: {
    [key: string]: string;
  };
}

export class Group extends Account {
  org: ''; // Organization name
  users: Array<number>; // when a group account these are the user members
  admins: Array<number>; // when a group account these are the admin users
  banner_image: string;
  secret1: string; // secret for publishers/sellers
  created: number;
  style?: PublisherStyleConfig;
  mailchimp?: GroupMailchimpSettings;

  address: Address;
  website: string;

  constructor() {
    super();
    this.id = 0;
    this.org = '';
  }

  // smk: Tried wiring this here, but it won't work until a Publisher (aka 'Group') is consistently blessed into this class.
  // getUrl_bannerImage(): string {
  //   let
  //     publisherId = this.id,
  //     url = `/api/group/${publisherId}/banner/image`;
  //
  //   // Try to protect against default id of 0...really caller's responsibility. Blank pixel?
  //   if (!publisherId) url = '';
  //
  //   return url;
  // }
  //
  // getUrl_profileImage(): string {
  //   let
  //     publisherId = this.id,
  //     url = `/api/group/${publisherId}/profile/image`;
  //
  //   // Try to protect against default id of 0...really caller's responsibility. Blank pixel?
  //   if (!publisherId) url = '';
  //
  //   return url;
  // }

}
