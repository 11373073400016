// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { VERSION } from './version';

export const environment = {
  gitHash: VERSION.hash,
  buildDate: VERSION.timestamp,
  production: false,
  stripePubKey: 'pk_test_ptTRYMFBhoF5J8wRyMOEkZut',
  stripeMode: 'TEST',
  recaptchaKey: '6LeXvPYjAAAAAGaLUy8N3AdO_zaBlf-shFdzaeNG',
  recaptchaInvisibleKey: '6Lfq_y0lAAAAAISv2MXDc0qSt0Z1q4cXcdK-H45b',
  imgBaseUrl: 'https://storage.googleapis.com/transact-imgs/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
