import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'loginHeadersFilter'
})
export class LoginHeadersFilterPipe implements PipeTransform {
  transform(items: any[], arg?: any): any {
    if (!items) {
      return;
    }

    if (arg && arg.length) {
      return items
        .filter(item =>
          (item.city && item.city.toUpperCase().indexOf(arg.toUpperCase()) >= 0) ||
          (item.country && item.country.toUpperCase().indexOf(arg.toUpperCase()) >= 0)
        );
    } else {
      return items;
    }
  }
}
