<section class="chart-wrapper">
  <div
    class="chart"
    [style.minWidth]="
      lineChartLabels && lineChartLabels.length > cartWidthLimit
        ? lineChartLabels.length * 40 + 'px'
        : '100%'
    "
  >
    <canvas
      baseChart
      [height]="300"
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
      [type]="lineChartType"
      [plugins]="lineChartPlugins"
    >
    </canvas>
  </div>
</section>
<span class="swipe-info" *ngIf="lineChartLabels.length > cartWidthLimit"
  >swipe left or right to navigate</span
>
