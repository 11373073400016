
import {
    Component,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    ElementRef} from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams} from '@angular/common/http';
import { UtilityService } from '../index';

@Component({
    selector: 'app-file-uploader',
    templateUrl: 'file-upload.component.html',
    styleUrls: ['file-upload.component.scss'],
})
export class FileUploadComponent {
    @ViewChild('inputFile') inputFile: ElementRef;
    @Input() label = 'Upload image file';
    @Input() description = 'Please select your image file that you want to upload';
    @Input() uploadOptions?: {
        url: string;
        body?: {[k: string]: string};
        headers?: {[k: string]: string};
        params?: {[k: string]: string};
    };
    @Input() showMessages = true;
    @Input() showDescription = true;
    @Output() fileUploadedEvent: EventEmitter<any> = new EventEmitter();

    processingState = false;
    messageInfo: {type: string; message: string};

    constructor(
        private utilitySvc: UtilityService,
        private http: HttpClient
    ) { }

    uploadFileOnChange(event: any) {
        const file = event.target.files[0];
        console.log('on change: ', file);

        if (/^image\//.test(file.type)) {
            console.log('upload image file to server');
            this.sendToServer(file);
        } else if (file.type.toString().indexOf('/pdf') > 1) {
            console.log('upload pdf file to server');
            this.sendToServer(file);
        } else {
            console.warn('You could only upload images.');
            this.processingState = false;
        }
    }

    selectUploadFile() {
        this.processingState = false;
        this.resetMessages();
        this.inputFile.nativeElement.click();
    }

    sendToServer(file: File) {
        this.processingState = true;
        const formData: FormData = new FormData();

        formData.append('name', file.name);
        formData.append('file', file);

        if (this.uploadOptions.body) {
            // eslint-disable-next-line guard-for-in
            for (const option in this.uploadOptions.body) {
                formData.append(option, this.uploadOptions.body[option].toString());
            }
        }

        const headers = new HttpHeaders()
            .set('ngsw-bypass', 'true');

        if (this.uploadOptions.headers) {
            // eslint-disable-next-line guard-for-in
            for (const option in this.uploadOptions.headers) {
                headers.set(option, this.uploadOptions.headers[option].toString());
            }
        }

        const params = new HttpParams();

        if (this.uploadOptions.params) {
            // eslint-disable-next-line guard-for-in
            for (const option in this.uploadOptions.params) {
                params.set(option, this.uploadOptions.params[option].toString());
            }
        }

        const requestOptions = {
            headers,
            params,
            observe: 'events' as 'body',
            reportProgress: true
        };

        this.http.post<any>(this.uploadOptions.url, formData, requestOptions)
            .toPromise()
            .then(res => {
                console.log('upload result', res);
                this.processingState = false;
                this.messageInfo = {
                    type: 'success',
                    message: 'Your image has been uploaded successfully!'
                };

                this.fileUploadedEvent.emit(true);
            })
            .catch(err => {
                console.log('error after upload', err);
                this.messageInfo = {
                    type: 'error',
                    message: 'Something went wrong. Try again!'
                };
                this.processingState = false;
            });
    }

    showMessage(type: string, message: string) {
        this.messageInfo = {
            type,
            message
        };
    }

    resetMessages() {
        this.messageInfo = null;
    }
}
