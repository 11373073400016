import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UtilityService } from '../index';

@Component({
  selector: 'app-notification-bar',
  templateUrl: 'notification-bar.component.html',
  styleUrls: ['notification-bar.component.scss'],
})
export class NotificationBarComponent implements OnInit, OnChanges {
  @Input() msg?: string;
  @Input() sKey?: string;
  @Input() purchaseWindow: boolean;

  message: string;
  storageKey: string;
  showNotification: boolean;

  constructor(private utilitySvc: UtilityService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.msg && changes.sKey) {
      this.message = changes.msg.currentValue;
      this.storageKey = changes.sKey.currentValue;
      this.showNotification =
        this.message &&
        this.storageKey &&
        this.utilitySvc.allowLocalStorage() &&
        localStorage.getItem(this.storageKey) !== 'set'
          ? true
          : false;
    }
  }

  ngOnInit() {
    this.utilitySvc.notificationBar.subscribe((res) => {
      if (res) {
        this.message = res.message;
        this.storageKey = res.storageKey;
        this.showNotification =
          this.message &&
          this.storageKey &&
          this.utilitySvc.allowLocalStorage() &&
          localStorage.getItem(this.storageKey) !== 'set'
            ? true
            : false;
      }
    });
  }

  closeNotificationBar() {
    localStorage.setItem(this.storageKey, 'set');
    this.showNotification = false;
  }
}
