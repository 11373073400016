<div *ngIf="transactionList.length === 0">
  <em>{{ getOption('noItemsMessage') }}</em>
</div>

<div *ngIf="transactionList.length > 0" >

  <div [ngSwitch]="getOption('displayFormat')">

    <ng-container *ngSwitchCase="'table-3-col'">
      <table class="xct-table xct-format-{{ getOption('displayFormat') }} table table-hover">
        <thead>
          <tr>
            <th class="xct-col-timestamp">Completed</th>
            <th class="xct-col-action"></th>
            <th class="xct-col-amount">Amount</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let transaction of transactionList; index as i; trackBy: transactionTrackBy">
            <tr [ngClass]="{'xct-inflow': transaction.isAccountInflow(), 'xct-outflow': transaction.isAccountOutflow() }"  >
              <td class="xct-col-timestamp">
                <div class="xct-timestamp-month">{{ transaction.timestamp | date: 'MMM' }}</div>
                <div class="xct-timestamp-day">{{ transaction.timestamp | date: 'dd' }}</div>
              </td>
              <td class="xct-col-action">
                <a (click)="goToUrl(transaction)"
                    [title]="transaction.title"
                    *ngIf="transaction.url">{{ transaction.title }}</a>
                <span *ngIf="!transaction.url">{{ transaction.title }}</span>
                <div *ngIf="optionsForSingle.isDebug" class="xct-debug-json">{{ transaction | json }}</div>
              </td>
              <td class="xct-col-amount">
                {{ transaction.token_cents | xsactCurrency:transaction.token_class:'hundredth_c' }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngFor="let transaction of transactionList; index as i; trackBy: transactionTrackBy">
        <app-elements-transaction-single [transaction]="transaction" [options]="optionsForSingle" [counter]="i + 1" [totalCount]="transactionList.length"></app-elements-transaction-single>
      </ng-container>
    </ng-container>

  </div>

</div>
