import { Component, Input, OnInit, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-subscriptions-line-chart',
  templateUrl: './subscriptions-line-chart.component.html',
  styleUrls: ['./subscriptions-line-chart.component.scss'],
})
export class SubscriptonsLineChartComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  @Input() chartData: ChartData[] = [];
  public lineChartData: ChartData[] = [];
  public lineChartLabels: string[] = [];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    maintainAspectRatio: false,
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno',
          },
        },
      ],
    },
    plugins: {
      legend: {
        align: 'start',
      },
    },
  };

  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  public cartWidthLimit = 20;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData) {
      this.lineChartData = changes.chartData.currentValue.data;
      this.lineChartLabels = changes.chartData.currentValue.labels;
      this.chart.update();
    }
  }
}
