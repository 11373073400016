<div class="form-group" *ngIf="enableStep === 0 && user && !user.totp_enabled">
  <button type="button" class="tt--button button--black large tt__m-0 tt__m-t-10 tt__width-100p" (click)="start2Fa()" i18n>Set up two-factor authentication</button>
</div>
<div class="form-group" *ngIf="user && user.totp_enabled">
  <p><label i18n>Two factor authentication is enabled for this account.</label></p>
  <button type="button" class="btn btn-primary btn-sm" (click)="disable2Fa()" i18n>Disable two-factor authentication</button>
</div>

<div class="f2a-error-message" *ngIf="error.enable && error.message">
  <app-validated-input class="alert-style" [errors]="errorMessages">
    <app-validation-message i18n displayOn="FAILED_2FA">Authentication code was not accepted, please try again.</app-validation-message>
    <app-validation-message i18n displayOn="QR">There was an error with QR code generator, please try again.</app-validation-message>
    <app-validation-message displayOn="*">{{ error.message }}</app-validation-message>
  </app-validated-input>
  <p class="text-left">
    <button class="btn btn-primary" (click)="disableErrorMsg()" i18n>Try Again</button>
  </p>
</div>

<div *ngIf="enableStep === 1 && !error.enable" class="fa-step-1">
  <div class="info">
    <p i18n>You must have a two factor authorization app installed on your mobile device.</p>
    <p i18n>Some options include:</p>
    <ul>
      <li><a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank" i18n>Google Authenticator for IOS</a>,</li>
      <li><a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"  target="_blank" i18n>Google Authenticator for Android</a></li>
      <li><a href="https://www.authy.com/app/mobile/"  target="_blank" i18n>Authy</a>.</li>
    </ul>
    <a class="tt--button button--white tt__m-t-10"
      href="http://funcshun.com/two-factor-authentication-dummies/"
      target="_blank" i18n>Discover more</a>
  </div>

  <div *ngIf="!isMobile" class="code-scan">
    <p i18n>Scan this barcode with the two factor authentication app on your mobile device</p>
    <div class="twofa-scan" #twoFaElement></div>
  </div>
  <div *ngIf="isMobile" class="code-scan">
    <p i18n>Click <a #twoFaLink (click)="showVerification()"> this link </a> to get your two factor authentication code.  Copy it and return back here to enter the code.
    </p>
  </div>
  <div class="row buttons">
    <div class="col-sm-12">
      <button class="btn btn-primary pull-right" (click)="showVerification()" i18n>Next</button>
      <button (click)="enableStep = 0" type="button" class="btn btn-default pull-right" i18n>Cancel</button>
    </div>
  </div>
</div>

<div *ngIf="enableStep === 2 && !error.enable">
  <form [formGroup]="twoFaForm" (ngSubmit)="toggleEnableDisable(true)">
    <div class="form-group">
      <label for="authcode" class="formHeading" i18n>Authentication Code</label>
      <input id="authcode" [formControl]="twoFaForm.controls['authentication_code']" class="form-control input-lg">
    </div>
    <div class="button-separation row">
      <div class="col-sm-12">
        <button class="btn btn-lg btn-primary col-sm-12" id="verify_button"
          [disabled]="twoFaForm.controls['authentication_code'].value===''" i18n>Verify</button>
      </div>
    </div>
    <p i18n>You about to enable two-factor authentication for your account.</p>
    <p i18n>Using the two-factor authentication app on your mobile device where you scanned the barcode, get your authorization code.</p>
    <p i18n>Enter the code here, and verify your identity.</p>
  </form>
</div>

<div *ngIf="enableStep === 3 && !error.enable">
  <div *ngIf="showRescueCodes" class="rescue-codes">
    <p i18n>
      Copy these codes into a secure place, in case you are unable to use your mobile authentication app.
      Each of these codes can be used once in place of the authentication code from your mobile app.
    </p>
    <textarea id="recovery-codes" class="recovery-codes" wrap="hard" rows="3" cols="65">{{ oneUseCodes }}</textarea><br/>
    <button class="btn btn-primary rescue-codes__buttons" (click)="copyCodesToClipboard()" i18n>Copy to clipboard</button>
    <button class="btn btn-primary rescue-codes__buttons" (click)="showRescueCodes = false" i18n>OK, I've copied them.</button>
  </div>
</div>
