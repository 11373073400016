<div *ngIf="!hasLogs()">
  <em>{{ getOption('noItemsMessage') }}</em>
</div>

<div *ngIf="hasLogs()" class="media">

<table class="table">
  <thead>
    <tr>
      <th>When</th>
      <th>What Changed</th>
      <th>Who Changed It</th>
      <th>IP Address</th>
      <th>Location</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let log of getLogs()">
      <td>{{ log.timestamp | date:'medium' }}</td>
      <td>{{ log.what_changed }}</td>
      <td>{{ log.who_changed_email + ' (' + log.who_changed_id + ')' }}</td>
      <td>{{ log.ip_address }}</td>
      <td>{{ log.geo_ip_location == '' ? '-' : log.geo_ip_location }}</td>
    </tr>
  </tbody>
</table>

</div>
