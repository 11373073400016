import { FormGroup } from '@angular/forms';

export class XctTypesUtilityShim {

  // API 'PUT' call wants a FULL, FLAT representation of an object's fields as per API specification.
  extractFieldsHashFromForm(object: any, form: FormGroup) {
    // console.error(`start flattenedHashRepresentation`);
    const fieldsFoundInFormHash: any = {};
    const flattenedHashRepresentation = this.flattenAllFieldsIntoHash(object);
    const fieldsThatChangedHash: any = {};

    // console.error(`continue flattenedHashRepresentation`, flattenedHashRepresentation);

    // Loop over the flattened representation which has ALL the valid keys for this object.
    // Check each valid key to see if this current form included a data field for that key.
    Object.keys(flattenedHashRepresentation)
      .map(classPropertyName => {
        // let extantValue =
        if (form.controls[classPropertyName]) {
          const formFieldValue = form.controls[classPropertyName].value;
          fieldsFoundInFormHash[classPropertyName] = formFieldValue;
        }
      });

    // console.info(`flattenedHashRepresentation for '${object.constructor.name}'`, flattenedHashRepresentation);
    // console.info(`fieldsFoundInFormHash for '${object.constructor.name}'`, fieldsFoundInFormHash);

    // Iterate over keys of this class' flattened data representation.
    // Check if form has any *matching* field names submitted as data in the form.
    Object.keys(fieldsFoundInFormHash)
      .map(validKey => {
        // console.error(`validKey ''${validKey}' = ${form.controls[validKey]}`, form.controls[validKey]);

        // The form had a matching field for this property name.
        if (form.controls[validKey]) {
          const formFieldValue = form.controls[validKey].value;
          const extantValue = flattenedHashRepresentation[validKey];

          // Keep track of form data that *differs* from extant data.
          if (formFieldValue !== extantValue) {
            // console.info(`something changed in '${validKey}': ${extantValue} > ${formFieldValue}`);
            flattenedHashRepresentation[validKey] = formFieldValue;
            fieldsThatChangedHash[validKey] = formFieldValue;
          }
        }
      });

    // if (Object.keys(fieldsThatChangedHash).length) {
    // console.info(`fieldsThatChangedHash`, fieldsThatChangedHash);
    // }

    // console.warn(`outboundFlattenedFieldsHash`, outboundFlattenedFieldsHash);
    return fieldsFoundInFormHash;
  }

  // Recursive way to iterate overall all properties of a class and build a simple hash of values. Any nested objects will have
  // their keys/values flattened into parent.
  public flattenAllFieldsIntoHash(object: any, propertyName: string = '', baseHash: any = []) {

    // console.warn(`propertyName=${propertyName} in object`, object);

    const
      // object: object = this,
      propertyValue: any = (object as any)[propertyName];

    // Examine a specific property as requested.
    if (propertyName) {
      // Currently only catching class fields that have been INITIALIZED. Skipping non-initialized fields.
      // console.info(`propertyName "${propertyName}" typeof is '${typeof propertyValue}'`, )

      // This is case where frontend object definition (Type) has a field defined, but server did not provide data
      // for this field to frontend. Per guidance, force value of such fields to emptry string for submission to server.
      if (propertyValue === null) {
        // console.error(`propertyName "${propertyName}" is null, so set it to empty string`, )
        baseHash[propertyName] = '';
        // baseHash[propertyName] = null;
      } else if (typeof propertyValue === 'object') {
        // If the property value is a NESTED object, evaluate that object recursively.
        // let classname = propertyValue.constructor.name; // e.g., Bank, Address
        // console.warn(`typeof ${propertyValue} === 'object'`, )
        // console.info(`Found nested object ${propertyValue.constructor.name}`, )
        // propertyValue._flattenAllFieldsIntoHash('', baseHash);
        this.flattenAllFieldsIntoHash(propertyValue, '', baseHash);
      } else {
        // Simple case where the property value is a string or number or non-proprietary Javascript prototype.
        baseHash[propertyName] = propertyValue;
      }
    } else {
      // This is the first call for this object, so iterate over keys and evaluate each value.
      // console.error(`first call`, object);
      Object.keys(object)
        .map(key => {
          this.flattenAllFieldsIntoHash(object, key, baseHash);
        });
    }

    return baseHash;
  }

  // It's nice to ONLY send data that has changed, not necessarily *all* the data we have in a hash.
  public pluckOnlyChangedFieldsFromForm(object: any, formGroup: FormGroup, fieldsToCheck: Array<string>) {
    const oldHashRepresentation = this.flattenAllFieldsIntoHash(object);
    const formFlattenedRepresentation = this.extractFieldsHashFromForm(object, formGroup);
    const differencesHash = {};

    for (const fieldToCheck of fieldsToCheck) {
      const oldValue = oldHashRepresentation[fieldToCheck];
      const formValue = formFlattenedRepresentation[fieldToCheck];

      // if (formValue !== oldValue) {
      if (formValue !== oldValue) {
        // console.warn(`difference for '${fieldToCheck}'! formValue ${formValue} != old ${oldValue}`, differencesHash);
        (differencesHash as any)[fieldToCheck] = formValue;
      }
    }

    // console.warn(`flattenedHashRepresentation`, oldHashRepresentation);
    // console.warn(`formFlattenedRepresentation`, formFlattenedRepresentation);
    // console.warn(`fieldsToCheck`, fieldsToCheck);
    // console.warn(`differencesHash`, differencesHash);

    return differencesHash;
  }

}
