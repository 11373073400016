import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
      path: 'publisher',
      loadChildren: () => import('./publisher/publisher.module')
        .then( m => m.PublisherModule)
    },
    {
      path: 'purchase',
      loadChildren: () => import('./purchase/purchase.module')
        .then( m => m.PurchaseModule)
    },
    {
      path: 'user',
      loadChildren: () => import('./user/user.module')
        .then( m => m.UserModule)
    },
    {
      path: '',
      loadChildren: () => import('./public/public.module')
        .then( m => m.PublicModule)
    }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
