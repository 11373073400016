import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of, forkJoin } from 'rxjs';
import { AccountFunds } from '../types/account_funds';
import { User } from './user';
import { Group } from './group';
import { Subscription } from '../types/subscription';
import { Bank } from '@shared/types/bank';

import { map, catchError } from 'rxjs/operators';
import { Document } from '../types/document';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { UtilityService } from './utility';
import { Transaction, TransactionType } from './transaction';

const groupBaseUrl = '/api/group/';
const adminBaseUrl = '/api/admin/';

@Injectable()
export class GroupService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private utilitySvc: UtilityService
    ) { }

  authError(error: Response) {
    console.log('GroupService authentication ERROR');
    console.log(error);

  }

  respOK() {
    console.log('respOK');
  }

  getAdminGroupProfile(groupId: number): Promise<Group> {
     const url = adminBaseUrl + '/account/' + groupId.toString() + '/profile';
     return this.http.get<Group>(url)
      .toPromise();
  }

  getGroupBalance(groupId: number): Observable<AccountFunds> {
    return this.http.get<AccountFunds>( groupBaseUrl + groupId + '/tokens');
  }

  getGroupMembers(groupId: number): Observable<User[]> {
    const url = groupBaseUrl + groupId.toString() + '/members';
    return this.http.get<User[]>(url);
  }

  getGroupSubscriptions(groupId: number): Observable<Subscription[]> {
    const url = groupBaseUrl + groupId.toString() + '/subscription/plans';
    return this.http.get<Subscription[]>(url);
  }

  public activateGroupSubscriptionType(groupId: number, data: any): Promise<any> {
    const body = Object.assign({}, {
      plan_type: data.plan,
      token_price: data.price
    });

    return this.http.post<Subscription[]>(`${groupBaseUrl}${groupId}/subscription/plan`, body, {})
      .toPromise();
  }

  public deactivateGroupSubscriptionType(groupId: number, planType: string): Promise<any> {
    return this.http.delete<Subscription[]>(`${groupBaseUrl}${groupId}/subscription/plan/${planType}`)
      .toPromise();
  }


  getGroupSubscriberCsv(groupId: number): Observable<string> {
    const url = groupBaseUrl + groupId.toString() + '/subscribers/csv';
    // This request comes back as a plain string with a csv mime type
    return this.http.request<string>('GET', url, { responseType: 'text' } as any) as any;
  }

  public getGroupProfile(groupId: number): Observable<Group> {
    return this.http.get<Group>(`${groupBaseUrl}${groupId}/profile`);
  }

  public getGroupBank(groupId: number): Observable<Bank> {
    return this.http.get(`${groupBaseUrl}${groupId}/bank`)
      .pipe(
        map((result: Bank) => new Bank(result === null ? {} : result))
      );
  }

  public getGroupDocumentList(groupId: number): Promise<Document[]> {
    return this.http.get<Document[]>(`${groupBaseUrl}/${groupId}/document/list`)
      .toPromise();
  }

  public updateGroupProperty(groupId: number, fieldsHashToSave: any = {}): Promise<Group> {
    return this.http.post<Group>(`${groupBaseUrl}/${groupId}/profile`, fieldsHashToSave)
      .toPromise();
  }

  public getGroupTransactions(groupId: number, limit?: number, transTypes?: TransactionType[]) {
    const httpRequestsToMake: Observable<Transaction[]>[] = [];
    if (!transTypes) {
      transTypes = [null];
    }

    if (!limit) {
      limit = 100;
    }

    for (const transactionType of transTypes) {
      let params = new HttpParams();
      if (transactionType === TransactionType.BANK_OUT) {
        params = params.append('buyer_id', groupId.toString());
      } else {
        params = params.append('recipient_id', groupId.toString());
      }
      if (transactionType !== null) {
        params = params.append('trans_type', transactionType.toString());
      }
      params = params.append('limit', limit.toString());

      const observable = this.http.get('/api/transact/list', { params })
        .pipe(
          map((decodedArray: Array<any>) => {
            const processedArray = new Array<Transaction>();
            for (const rawHash of decodedArray) {
              rawHash.buyer_balance = rawHash.buyer_ballance || rawHash.buyer_balance;

              processedArray.push(new Transaction(rawHash));
            }
            return processedArray;
          }),
          catchError(
            () => of([])
          )
        );

      httpRequestsToMake.push(observable);
    }

    return forkJoin(httpRequestsToMake).pipe(
      map((transactions) => [].concat(...transactions) as Transaction[])
    );
  }

  public async getCurrentPublisherId(route: ActivatedRouteSnapshot | ActivatedRoute, user?: boolean | string) {
    const publisherId = await this.userService.getPublisherId('snapshot' in route ? route.snapshot : route);
    if (publisherId) {
      return publisherId;
    } else {
      if (user) {
        console.log('This user is not publisher, or does not belong to any group. Returning empty value.');
        return -1;
      } else {
        this.utilitySvc.showAppNotificationModal(
          'Transact Error',
          'Your profile does not include any publisher details. Please refresh the website or contact our support.'
        );

        console.error('Error getting publisherID: ', publisherId);
        return null;
      }
    }
  }
}
