import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {
    notificationBarSubject = new BehaviorSubject<any>(null);
    notificationBar = this.notificationBarSubject.asObservable();

    alertMessageSubject = new BehaviorSubject<any>(null);
    alertMessage = this.alertMessageSubject.asObservable();

    appNotificationModalSubject = new BehaviorSubject<any>(null);
    appNotificationModal = this.appNotificationModalSubject.asObservable();

    public delay10sec = 10000;
    public delay75sec = 7500;
    public delay5sec = 5000;
    public delayDefault = 1800;
    public errorColor = '#ff6f59';    // Transact red
    public successColor = '#17A4AD';  // Transact aqua

    private queue: any[];
    private toastrElement: HTMLElement;

    constructor() {
        this.queue = [];
    }

    showToastDelay(message: string, delayMS: number, color?: string) {
        const delay = delayMS || this.delayDefault;
        const backgroundColor = color || '#000';
        if (this.toastrElement) {
            this.toastrElement = this.toastrElement ||
                    document.getElementById('_$_toastr');

            if (this.toastrElement.classList.contains('in')) {
                this.queue.push({
                    message, delay, color : backgroundColor
                });
            } else {
                this.setMessage(message, delay, backgroundColor);
            }
        }
    }

    showToast(message: string, color?: string) {
        this.showToastDelay(message, null, color);
    }

    allowLocalStorage() {
        try {
            localStorage.setItem('testStorage', 'testStorage');
            localStorage.removeItem('testStorage');
            return true;
        } catch (e) {
            return false;
        }
    }

    showNotificationBar(message: string, storageKey: string) {
        this.notificationBarSubject.next({
            message,
            storageKey
        });
    }

    showAppNotificationModal(title: string, message: string): void {
        this.appNotificationModalSubject.next({
            title,
            message
        });
    }

    public convertRouteWithPublisherId(originalRoute: string, publisherId?: number | string): string {
        if (publisherId) {
            const splitRoute = originalRoute.split('/').filter((e) => e);
            let index = 0;
            splitRoute.map((e, i) => {
                if (e === 'publisher') {
                    index = i + 1;
                }
            });
            splitRoute.splice(index, 0, publisherId.toString() || '');
            return '/' + splitRoute.join('/');
        } else {
            return originalRoute;
        }
    }

    private sendAlertMessage(message: string, container?: string) {
        this.alertMessageSubject.next({
           message,
           container: container ? container : ''
        });
    }

    private setMessage(message: string, delay: number, color: string) {
        if (this.toastrElement) {
            this.toastrElement.innerHTML = message;
            this.toastrElement.style.backgroundColor = color;
            this.toastrElement.classList.add('in');
            this.toastrElement.classList.remove('hidden');
            const self = this;
            setTimeout( () => {
                self.toastrElement.classList.remove('in');
                self.toastrElement.classList.add('hidden');
                if (self.queue.length > 0) {
                    const nextItem = self.queue.shift();
                    self.setMessage(nextItem.message, nextItem.delay, nextItem.color);
                }
            }, delay );
        }
    }
}
