import { Component, Input } from '@angular/core';

import { XctFormHelper } from '../../types/xctFormHelper';

@Component({
  selector: 'app-form-elements-button-edit',
  templateUrl: 'form-elements-button-edit.component.html',
  styleUrls: ['../form-elements.scss', 'form-elements-button-edit.component.scss'],
})
export class FormElementsButtonEditComponent {
  // Allow an XctFormHelper to be passed. If one is not passed, use the defaults below.
  @Input() xctFormHelper: XctFormHelper;

  // Defaults that can be overridden manually or via XctFormHelper.
  // @Input() protected isHidden: boolean = true;
  @Input() labelText = 'Edit';
  @Input() iconClasses = 'fa fa-pencil';
  @Input() onClick: () => void;

  // TODO=constructor to ensure 'XctFormHelper' is passed?

  eventButtonClicked() {
    // Call explicit click handler function.
    if (this.onClick) {
      // console.info(`call eventButtonClicked onClick`);
      this.onClick();
    } else {
      // There is no explicit click handler function, so use the default naming scheme.
      // let functionName = this.xctFormHelper_functionToTrigger || 'trigger__EDITING';
      const functionName = 'trigger__EDITING';
      // console.info(`eventButtonClicked ${functionName}`);
      (this.xctFormHelper as any)[functionName]();
    }
  }

  // isThisShowing(): boolean {
  //   let formHelper = this.xctFormHelper;
  //   // if (formHelper) {
  //   return formHelper.isFormLoading() || formHelper.isFormSaving();
  //   // }
  //   // else {
  //   //   return !this.isHidden;
  //   // }
  // }

  // isThisHidden(): boolean {
  //   return !this.isThisShowing();
  // }

  // getLabelText(): string {
  //   // let text = this.savingMessageText;
  //   let text = this.labelText;
  //   // let formHelper = this.xctFormHelper;
  //   // if (formHelper && formHelper.editLabelText) {
  //   //   text = formHelper.editLabelText;
  //   // }
  //   return text;
  // }
}
